import React, { useContext, useEffect, useState } from "react"
import "./styles.css"
import Navbar from "../../components/Navbar"
import Footer from "../../components/Footer"
import { ReactComponent as Star } from "../../assets/star.svg"
import { ReactComponent as Vote } from "../../assets/vote.svg"
import { ReactComponent as Comment } from "../../assets/comment.svg"
import { ReactComponent as Share } from "../../assets/share.svg"
import { ReactComponent as Add } from "../../assets/add.svg"
import { ReactComponent as Manage } from "../../assets/settings.svg"
import { ReactComponent as Check } from "../../assets/checksign.svg"
import { ReactComponent as Delete } from "../../assets/delete.svg"
import { ReactComponent as Cross } from "../../assets/cross.svg"
import { ReactComponent as Edit } from "../../assets/edit.svg"
import { Link, useParams, Outlet, useLocation, Navigate } from "react-router-dom"
import Prompt from "../../components/Prompt"
import ImageSlider from "../../components/ImageSlider"
import { ImageUploadModal } from "../../components/ImageUpload"
import axios from "axios"
import { MasterContext } from "../../context/MasterContext"
import Spinner, { spinnerTypes } from "../../components/Spinner"
import { SlClock } from "react-icons/sl"
import { AiOutlineStop } from "react-icons/ai"
import { services } from "../../utils/methods"

const reviews = [
	// {
	// 	img: null,
	// 	name: "Kamal Krishna Sarma",
	// 	rating: "4.8",
	// 	type: "delivery",
	// 	time: "24 hours ago",
	// 	votes: 0,
	// 	comments: 0
	// },
]

const tabLinks = [
	{ path: "", label: "Order Online" },
	{ path: "reviews", label: "Reviews" },
	{ path: "photos", label: "Photos" }
]

const Business = () => {
	const location = useLocation()
	const { business_id } = useParams()
	const [selectedImages, setSelectedImages] = useState([])
	const [imageModal, setImageModal] = useState()
	const [business, setBusiness] = useState()
	const { masterState, setMasterState } = useContext(MasterContext)
	const business_editable = masterState?.businesses?.find(i => i.id === business_id)
	const [loading, setLoading] = useState()
	const businessDisabled = !business?.active_status?.by_vendor || business?.active_status?.by_admin <= 0

	useEffect(() => {
		if (!business_id) return
		;(async () => {
			setLoading(true)
			try {
				const response = await axios.get(`/business/${business_id}`, {
					headers: localStorage.getItem("token")
						? { Authorization: `Bearer ${localStorage.getItem("token")}` }
						: {}
				})
				if (response.data) {
					const { business, cover_images, editable } = response.data
					const coverImages = { center: [], cols: 0 }

					if (cover_images?.primary) {
						coverImages.primary = `${axios.defaults.baseURL}/${cover_images?.primary?.path}`
						coverImages.cols += 1
					}

					if (cover_images?.center?.top && cover_images?.center?.bottom) {
						coverImages.center.push(`${axios.defaults.baseURL}/${cover_images?.center?.top?.path}`)
						coverImages.center.push(`${axios.defaults.baseURL}/${cover_images?.center?.bottom?.path}`)
						coverImages.cols += 1
					}

					if (cover_images?.portrait) {
						coverImages.portrait = `${axios.defaults.baseURL}/${cover_images?.portrait?.path}`
						coverImages.cols += 1
					}

					setBusiness({ ...business, editable, cover_images: coverImages })
				}
			} catch (error) {}
			setLoading()
		})()
	}, [business_id])

	const isServiceBusiness = business?.business_nature === "service"
	if (isServiceBusiness && location?.pathname?.split("/")?.at(-1) === business_id) {
		return <Navigate to={"reviews"} />
	}

	return (
		<>
			<div id="business" className="page-wrapper">
				<Navbar />
				{business_editable &&
					(business?.active_status?.by_admin === -1 ? (
						<div id="business-status" className="flex pending">
							<SlClock />
							<span className="font-xs">Your business approval is pending.</span>
						</div>
					) : business?.active_status?.by_admin <= 0 || !business?.active_status?.by_vendor ? (
						<div id="business-status" className="flex disabled">
							<AiOutlineStop />
							<span className="font-xs">
								Your business{" "}
								{business?.active_status?.by_admin <= 0 ? "has been disabled by admin" : "is disabled"}.
							</span>
						</div>
					) : null)}
				{business?.cover_images?.primary && (
					<div id="business_cover_images" style={{ "--cols-count": business?.cover_images?.cols }}>
						<div>
							<div
								id="primary-cover"
								style={business?.cover_images?.cols === 1 ? { "--primary-w": "100%" } : null}
							>
								<img src={business?.cover_images?.primary} alt="Cover" />
							</div>
							{business?.cover_images?.center?.length === 2 && (
								<div id="center-cover">
									<img src={business?.cover_images?.center?.[0]} alt="Cover" />
									<img src={business?.cover_images?.center?.[1]} alt="Cover" />
								</div>
							)}
							{business?.cover_images?.portrait && (
								<div id="portrait-cover">
									<img src={business?.cover_images?.portrait} alt="Cover" />
								</div>
							)}
						</div>
					</div>
				)}
				<div id="profile-info">
					<div>
						<div className="flex start">
							<h2 className="heading">{business?.title}</h2>
						</div>
						{business_editable && business?.active_status?.by_admin > 0 && (
							<Link to={"form"}>
								<button className="plant-edit-btn">
									<Edit width={"32px"} height={"32px"} />
								</button>
							</Link>
						)}
					</div>
					<div>
						<div className="font-s">
							<span>
								<b>City</b> : {business?.city}
							</span>
						</div>
						<div className="font-s">
							<span>
								<b>Locality</b> : {business?.locality}
							</span>
						</div>
						<div className="font-s">
							<span>
								<b>Address</b> : {business?.complete_address}
							</span>
						</div>
						<div className="font-s">
							<span>
								<b>Contact</b> :
								<div className="nr-num-wrpr">
									{Object.values(business?.contact_number || {})?.map((i, idx) => (
										<>
											{idx > 0 && i && <span>|</span>}
											<a href={`tel:${i}`} className="font-s underline">
												{i}
											</a>
										</>
									))}
								</div>
							</span>
						</div>
						{business?.services && Object.values(business?.services)?.[0] && (
							<div className="font-s">
								<span>
									<b>Services</b> :{" "}
									<div className="nr-num-wrpr">
										{services
											?.filter(i => business?.services?.[i.key])
											?.map((i, idx) => (
												<>
													{idx > 0 && i && ", "}
													{i.label}
												</>
											))}
									</div>
								</span>
							</div>
						)}
					</div>
					{business_editable && !businessDisabled && (
						<div className="profile-btns-container">
							{!isServiceBusiness && (
								<Link
									to={`/business/${business_id}/product`}
									onClick={e => {
										if (!masterState?.premium && !masterState?.premiumModalVisible) {
											e.preventDefault()
											setMasterState(prev => ({ ...prev, premiumModalVisible: true }))
										}
									}}
								>
									<button className="theme-btn outlined theme-font">
										<Add stroke="var(--neon)" />
										Add Products
									</button>
								</Link>
							)}
							<button className="theme-btn outlined theme-font" onClick={() => setImageModal(true)}>
								<Add stroke="var(--neon)" />
								Add Business Images
							</button>
						</div>
					)}
					{/* <div className="profile-btns-container">
						<button className="theme-btn outlined theme-font">
							<Direction />
							<span className="theme-font">Directions</span>
						</button>
						<button className="theme-btn outlined theme-font">
							<Share strokeWidth={"0px"} width={"25px"} height={"25px"} />
							<span className="theme-font">Share</span>
						</button>
					</div> */}
				</div>
				<div id="profile-tabs-wrapper">
					<div id="ptabs" className="font-md">
						{tabLinks.map((i, idx) =>
							isServiceBusiness && idx === 0 ? null : (
								<Link key={i.path} to={i.path}>
									<div className={i.path === (location?.pathname?.split("/")?.[3] || "") ? "selected" : ""}>
										<span>{i.label}</span>
									</div>
								</Link>
							)
						)}
					</div>
				</div>
				<div id="pt-content">
					<Outlet />
				</div>
				<Footer wrapper="footer-content-wrapper" />
				{loading && <Spinner type={spinnerTypes.spinner} {...{ overlay: true }} />}
				{imageModal && (
					<ImageUploadModal
						images={selectedImages}
						setImages={setSelectedImages}
						heading={"Upload Business Images"}
						instruction={"Images should be less than 400kb"}
						close={() => setImageModal(false)}
					/>
				)}
			</div>
		</>
	)
}

export const OrderOnline = () => {
	const { business_id } = useParams()
	const [categorySelection, setCategorySelection] = useState()
	const [sliderState, setSliderState] = useState()
	const [data, setData] = useState()

	const { masterState } = useContext(MasterContext)
	const business_editable = masterState?.businesses?.find(i => i.id === business_id && !i.disabled)

	useEffect(() => {
		if (!business_id && !masterState?.categories) return
		;(async () => {
			try {
				const response = await axios.get(`/product/list`, {
					params: { business_id },
					headers: localStorage.getItem("token")
						? { Authorization: `Bearer ${localStorage.getItem("token")}` }
						: {}
				})

				setData({
					...response.data,
					categories: response.data.categories.map(i => {
						const cat = masterState?.categories?.find(_i => _i._id === i._id)
						return {
							...i,
							category_name: cat?.name,
							products: i.products.map(p => ({
								...p,
								...(p.subcategory
									? { subcategory_name: cat?.subcategories?.find(s => s._id === p.subcategory)?.name }
									: {})
							}))
						}
					})
				})
			} catch (error) {}
		})()
	}, [business_id, masterState])

	const showSlider = async product_id => {
		const response = await axios.post(`product/images`, {
			business_id,
			product_id
		})
		if (response?.data?.length)
			setSliderState({
				active: true,
				selection: 0,
				srcs: response?.data?.map(i => `${axios.defaults.baseURL}/${i?.path}`)
			})
	}

	return (
		<div id="order-online">
			<div>
				<button className={!categorySelection ? "selected" : ""} onClick={() => setCategorySelection()}>
					<span>All</span>
				</button>
				{data?.categories?.map((i, idx) => (
					<button
						key={i._id}
						className={categorySelection === i._id ? "selected" : ""}
						onClick={() => setCategorySelection(i._id)}
					>
						<span>{masterState?.categories?.find(_i => _i._id === i._id)?.name}</span>
					</button>
				))}
			</div>
			<div>
				<h2 className="font-m">Order Online</h2>
				<div>
					{data?.categories
						?.filter(i => !categorySelection || categorySelection === i._id)
						?.map(category => (
							<>
								{category?.products?.map(i => (
									<div key={i._id} className="oprod">
										<img
											className="oprod-img"
											src={
												i?.images?.primary
													? `${axios.defaults.baseURL}/${i?.images?.base_path}/${i?.images?.primary}`
													: "/images/placeholder.nobg.png"
											}
											alt={i.name}
											onClick={() => (i?.images?.primary ? showSlider(i?._id) : "")}
											style={!i?.images?.primary ? { background: "#f7f8fa" } : null}
											width={200}
											height={200}
										/>
										<div className="oprod-content">
											<div>
												<div>
													<b className="font-md">{i.name}</b>
												</div>
												<div>
													<b className="theme-font font-m">₹{i.price}</b>
												</div>
												<div>
													{!categorySelection ? (
														<div className="font-xs">
															<span>
																<b>Category : </b>
																{category?.category_name}
																{i.subcategory_name && ` • ${i.subcategory_name}`}
															</span>
														</div>
													) : null}

													{i.stock ? (
														<div className="font-xs">
															<span>
																<b>Current Stock : </b>
																{i.stock}
															</span>
														</div>
													) : null}

													{i.plant_is_in ? (
														<div className="font-xs">
															<span>
																<b>Plant is in : </b>
																{i.plant_is_in}
															</span>
														</div>
													) : null}

													{i.size ? (
														<div className="font-xs">
															<span>
																<b>Size : </b>
																Height: {i.size?.height}, Width: {i.size?.width_spread}
															</span>
														</div>
													) : null}

													{i.avg_seeds ? (
														<div className="font-xs">
															<span>
																<b>Avg Seeds : </b>
																{i.avg_seeds}
															</span>
														</div>
													) : null}

													{i.variety ? (
														<div className="font-xs">
															<span>
																<b>Variety : </b>
																{i.variety}
															</span>
														</div>
													) : null}

													{i.kind ? (
														<div className="font-xs">
															<span>
																<b>Kind : </b>
																{i.kind}
															</span>
														</div>
													) : null}

													{i.color ? (
														<div className="font-xs">
															<span>
																<b>Color : </b>
																{i.color}
															</span>
														</div>
													) : null}

													{i.weight ? (
														<div className="font-xs">
															<span>
																<b>Weight : </b>
																{i.weight}KG
															</span>
														</div>
													) : null}
												</div>
											</div>
										</div>
										<div>
											{business_editable && data?.editable && (
												<Link to={`/business/${business_id}/product/${i._id}`}>
													<button className="plant-edit-btn">
														<Edit />
													</button>
												</Link>
											)}
										</div>
									</div>
								))}
							</>
						))}
				</div>
			</div>
			{sliderState?.active && (
				<ImageSlider
					srcs={sliderState?.srcs}
					currentPosition={sliderState?.selection}
					close={() => setSliderState(false)}
				/>
			)}
		</div>
	)
}

export const Reviews = () => {
	return (
		<div>
			<h2 className="font-m">All Reviews</h2>
			<div>
				{reviews.map(i => (
					<div className="review-card">
						<div>
							{i.img ? (
								<img src={i.img} alt="profile" />
							) : (
								<span className="img-text">{i.name.slice(0, 1).toUpperCase()}</span>
							)}
						</div>
						<div>
							<div className="rname">
								<b className="font-md">{i.name}</b>
							</div>
							<div className="rstats">
								<div>
									<span className="theme-font font-m">{i.rating}</span>
									<Star />
								</div>
								<div className="font-md">
									<span>{i.type}</span>
								</div>
								<div className="font-s">
									<span>{i.time}</span>
								</div>
							</div>
							<div className="font-xs">
								<span>
									{i.votes} Votes for helpful, {i.comments} Comments
								</span>
							</div>
							<div className="font-s ractions">
								<button>
									<Vote />
									Helpful
								</button>
								<button>
									<Comment />
									Comment
								</button>
								<button>
									<Share />
									Share
								</button>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

export const Photos = () => {
	const { business_id } = useParams()
	const [deleteState, setDeleteState] = useState({ selection: [] })
	const [sliderState, setSliderState] = useState()
	const [promptState, setPromptState] = useState()
	const [images, setImages] = useState()

	const { masterState } = useContext(MasterContext)
	const business_editable = masterState?.businesses?.find(i => i.id === business_id && !i.disabled)

	useEffect(() => {
		if (business_id)
			(async () => {
				try {
					const response = await axios.get(`/business/images/${business_id}`)
					if (response.data)
						setImages(response.data.map(i => ({ ...i, url: `${axios.defaults.baseURL}/${i.path}` })))
				} catch (error) {}
			})()
	}, [business_id])

	const deleteImages = async () => {
		try {
			setPromptState()
			await axios({
				method: "delete",
				url: `/business/images/${business_id}`,
				headers: {
					Authorization: `Beared ${localStorage.getItem("token")}`
				},
				data: { images: deleteState?.selection?.map(i => images?.find(_i => _i.name === i)?.path) }
			})

			setImages(prev => prev?.filter(i => !deleteState?.selection?.includes(i.name)))
			setDeleteState({ selection: [] })
		} catch (error) {}
	}

	return (
		<div id="bphotos">
			<div>
				<h2 className="font-m">All Photos</h2>
				{business_editable && (
					<div className="flex">
						{!deleteState.active ? (
							<button className="theme-btn small" onClick={() => setDeleteState({ selection: [], active: true })}>
								<Manage width={"22px"} height={"22px"} />
								Manage
							</button>
						) : (
							<>
								<button
									className="theme-btn small  grey"
									onClick={() => setDeleteState({ selection: [], active: false })}
								>
									<Cross width={"22px"} height={"22px"} fill="currentColor" />
									Cancel
								</button>
								<button
									className="theme-btn small danger"
									onClick={() => setPromptState(true)}
									disabled={!deleteState?.selection?.length}
								>
									<Delete />
									Delete <span style={{ width: "20px" }}>({deleteState?.selection?.length || 0})</span>
								</button>
							</>
						)}
					</div>
				)}
			</div>

			<div className="theme-gallery">
				{images?.map((i, idx) => (
					<div
						key={i.name}
						onClick={() =>
							deleteState.active && business_editable
								? setDeleteState(_i => ({
										..._i,
										selection: _i?.selection?.includes(i.name)
											? _i?.selection?.filter(_i => _i !== i.name)
											: _i?.selection?.concat([i.name])
								  }))
								: setSliderState({ active: true, selection: idx })
						}
					>
						<img src={i.url} alt="profile" width={220} height={220} />
						{business_editable && deleteState?.active && deleteState?.selection?.includes(i.name) && (
							<div className="flex selection">
								<Check />
							</div>
						)}
					</div>
				))}
			</div>

			{sliderState?.active && (
				<ImageSlider
					srcs={images?.map(i => i.url)}
					currentPosition={sliderState?.selection}
					close={() => setSliderState(false)}
				/>
			)}
			{business_editable && promptState && (
				<Prompt
					heading="Delete Photos"
					message="The selected photos will be removed permanently."
					info={
						deleteState?.selection?.length > 1
							? `${deleteState?.selection?.[0]} and ${deleteState?.selection?.length - 1} more...`
							: ""
					}
					Actions={() => (
						<>
							<button className="theme-btn grey" onClick={() => setPromptState(false)}>
								Cancel
							</button>
							<button className="theme-btn danger" onClick={() => deleteImages()}>
								Delete
							</button>
						</>
					)}
				/>
			)}
		</div>
	)
}

export default Business
