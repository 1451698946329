import React, { useContext, useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { MasterContext } from "../context/MasterContext"

const ProtectedRoutes = () => {
	const navigate = useNavigate()
	const { masterState, setMasterState } = useContext(MasterContext)

	useEffect(() => {
		if (!localStorage.getItem("token") && !masterState?.login) {
			setMasterState({ login: true })
			navigate("/")
		}
	}, [masterState, navigate, setMasterState])

	return masterState?.token ? <Outlet /> : null
}

export default ProtectedRoutes
