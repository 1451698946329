import React, { useContext, useEffect } from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import { MasterContext } from "../context/MasterContext"

const PremiumRoutes = () => {
	const { pathname } = useLocation()
	const { masterState, setMasterState } = useContext(MasterContext)

	useEffect(() => {
		if (!masterState?.premium && !masterState?.premiumModalVisible)
			setMasterState(prev => ({ ...prev, premiumModalVisible: true }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return masterState?.premium ? <Outlet /> : <Navigate to={pathname.slice(0, pathname.lastIndexOf("/"))} />
}

export default PremiumRoutes
