import React, { useEffect, useState } from "react"
import axios from "axios"
import SearchBar from "../../../components/SearchBar"
import { Link } from "react-router-dom"
import Spinner, { spinnerTypes } from "../../../components/Spinner"

import { HiViewGridAdd } from "react-icons/hi"
import { PiNewspaper } from "react-icons/pi"
import Layout from "../Layout"

const Blogs = () => {
	const [blogs, setBlogs] = useState()
	const [searchState, setSearchState] = useState()
	const [loading, setLoading] = useState()

	useEffect(() => {
		;(async () => {
			try {
				setLoading(true)
				let response = await axios.get("/blog/list")
				if (response.data)
					setBlogs(
						response.data.map(i => ({
							...i,
							cover: i?.cover ? `${axios.defaults.baseURL}/${i?.cover}` : null
						}))
					)
			} catch (error) {}
			setLoading()
		})()
	}, [])

	const filteredBusinesses = () => {
		let collection = blogs
		if (blogs?.length)
			if (searchState?.length > 3) {
				const searchStr = searchState?.toLowerCase()
				collection = blogs.filter(i => i.title?.toLowerCase().includes(searchStr))
			}
		return collection
	}

	return (
		<Layout id="blogs">
			<div className="flex between wrap">
				<h1 className="heading">Blogs</h1>
				<div className="flex gap-m">
					<SearchBar value={searchState} onChange={e => setSearchState(e.target.value)} placeholder={"Search"} />
					<Link to={"/blogs/form"}>
						<button className="theme-btn outlined flex font-s">
							<HiViewGridAdd className="font-l" /> Write New Blog
						</button>
					</Link>
				</div>
			</div>
			<div className="ncards-wrapper">
				{filteredBusinesses()?.map(i => (
					<Link key={i._id} className="ncard pointer" to={i._id + window.location.search}>
						<div className="ncard-img">
							{i.cover ? (
								<img src={i.cover} alt="Blog Cover" />
							) : (
								<div className="flex h-full business-image-placeholder">
									<PiNewspaper />
								</div>
							)}
						</div>
						<div className="w-full">
							<div className="ncard-content">
								<div>
									<h2 className="subheading text-ellipse nowrap">{i.title}</h2>
									<p className="font-md text-ellipse">{i.content?.replace(/&nbsp;/g, " ")}</p>
								</div>
								<div>
									<span className="font-xs">{new Date(i.created_at).toDateString()}</span>
								</div>
							</div>
						</div>
					</Link>
				))}
			</div>
			{loading && <Spinner type={spinnerTypes.spinner} {...{ overlay: true }} />}
		</Layout>
	)
}

export default Blogs
