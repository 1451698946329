import React from "react"
import Layout from "./Layout"

const TermsOfSale = () => {
	return (
		<Layout>
			<h1 className="heading">TERMS OF SALE - PURCHASE ORDER, PURCHASE PRICE & TAXES </h1>
			<hr />
			<ol>
				<br />
				<li className="font-m">
					The Business owner(User) shall supply the Products strictly as per the specifications mentioned in the
					Purchase Order and within the timelines (for both production and delivery) mentioned therein.{" "}
				</li>

				<br />
				<li className="font-m">
					The payment terms of the Purchase Price shall be such that all the online payment shall be first received
					by the company and then company shall pay the amount to vendor after deducting the commission. In case
					payment is received in cash it will be then received directly by the Business owner(User) and the
					commission part will be paid by vendor to the company.{" "}
				</li>

				<br />
				<li className="font-m">
					Commission payable by Business owner(User) to Sharanyu will be 4% + GST (Tools and Accessories, Pots,
					Seeds) and 5% + GST (Plants and Fertilizer). In case of any change in commission, the clause here will be
					amended accordingly. The commission amount will be settled during the first week of following month, i.e.
					within 7 working days from the end of the current month. In case of delay in settlement by upto 30 days
					after the due date, the aggrieved party shall get an additional 5% of the commission amount. In case of
					further delay and failure legal actions will be taken{" "}
				</li>

				<br />
				<li className="font-m">
					Company is not involved in any process related to delivery of goods from vendor to purchaser. Vendors
					shall have a defined SOP for delivery of goods and shall maintain it. The SOP must define the current
					status while in the process of delivery and shall be updated to customers timely. Business owner(User) is
					solely responsible for all kinds of failure or delivery of damaged products. The updated SOP shall be
					displayed over the website and mobile applications.{" "}
				</li>

				<br />
				<li className="font-m">
					The Purchase Price shall be inclusive of Goods and Service Tax (GST) applicable on the purchase. The
					Business owner(User) shall file its GST returns under the relevant provisions of law and within
					prescribed timelines.{" "}
				</li>

				<br />
				<li className="font-m">
					It is expressly stated and agreed that the Company reserves the right to withhold the GST amount as may
					be claimed by the Vendor until the Vendor provides a confirmation on proper filing of such returns.
					Further, if the Vendor fails to comply with the GST requirements as mandated under the applicable laws,
					the Company shall have the right to withhold the GST amount as may be claimed by the Business owner(User)
					until such time till the Business owner(User) furnishes to the Company the proof of adhering to and
					complying with the applicable laws in relation to the GST rules/regulations.{" "}
				</li>

				<br />
				<li className="font-m">
					Business owner(User) shall maintain one active phone number over the website and any change in such
					number shall be updated in the website within 24 hrs. All the invoices shall be issued in the name of the
					Business owner(User) themselves. In case any Business owner(User) doesn't comply with the terms and
					conditions, their account will be delisted from the website.{" "}
				</li>

				<br />
				<li className="font-m">
					Business section and advertisement section are subscription based. Business owner(User) has to pay 99/-
					for one time subscription to access the sections. The amount is non-refundable.{" "}
				</li>
			</ol>

			<br />
			<p className="font-m text-center">This Agreement was last updated on April 4th 2024. </p>
		</Layout>
	)
}

export default TermsOfSale
