import React from "react"
import Layout from "./Layout"
import { MdOutlineLocalPhone, MdOutlineMail } from "react-icons/md"
import { GoCopy } from "react-icons/go"
import { Link } from "react-router-dom"

const ContactUs = () => {
	const handleCopy = text => {
		if (navigator?.clipboard?.writeText) navigator.clipboard.writeText(text)
		else if (navigator?.clipboard?.write) navigator.clipboard.write(text)
		else {
			try {
				const tempItem = document.createElement("input")
				tempItem.setAttribute("type", "text")
				tempItem.setAttribute("display", "none")
				tempItem.setAttribute("value", text)
				document.body.appendChild(tempItem)
				tempItem.select()
				document.execCommand("Copy")
				tempItem.parentElement.removeChild(tempItem)
			} catch (error) {}
		}
	}
	return (
		<Layout id="contact-us">
			<h1 className="heading">Contact Us</h1>
			<hr />
			<p className="font-m">Do you have any doubts or need help? You can reach us by phone or mail.</p>
			<div className="flex row start" style={{ flexWrap: "wrap", rowGap: "5px", marginTop: "28px" }}>
				<div
					className="theme-btn secondary font-s flex row between"
					style={{ width: "100%", maxWidth: "22rem", minWidth: "fit-content" }}
				>
					<Link to="tel:+916262901019" className="w-full">
						<button className="flex row start w-full">
							<MdOutlineLocalPhone className="font-l" />
							<span className="underline font-md">+91-6262901019</span>
						</button>
					</Link>
					<button className="copy-button" onClick={() => handleCopy("6262901019")}>
						<GoCopy className="font-l" />
					</button>
				</div>
				<div
					className="theme-btn secondary font-s flex row between"
					style={{ width: "100%", maxWidth: "22rem", minWidth: "fit-content" }}
				>
					<Link to="mailto:info.sharanyu@gmail.com" className="w-full">
						<button className="flex row start w-full">
							<MdOutlineMail className="font-l" />
							<span className="underline font-md">info.sharanyu@gmail.com</span>
						</button>
					</Link>
					<button className="copy-button" onClick={() => handleCopy("info.sharanyu@gmail.com")}>
						<GoCopy className="font-l" />
					</button>
				</div>
			</div>
			{/* <div className="font-m highlighted">
				<p className="font-m">
					We can go on and on and talk about our favourite subject endlessly. Afterall, it is our true love, strong
					passion and firm commitment towards plants that made us come up with <b>Sharanyu</b>.
				</p>
			</div>
			<h2 className="subheading">WHY SHARANYU?</h2>
			<hr />
			<p className="font-m">
				We noticed that gardening does interest people of all ages who truly believe that gardening is beautiful,
				meditative, healthy, exciting, rewarding and creative work. But what they lack is a deep understanding and
				accurate information about plants, how to sow different seeds, grow seasonal, air purifier plants & most
				importantly the remedy to maintain them. People also lack the knowledge to choose the right plant, the
				right soil and at the right weather, accessibility to buy proper tools and accessories to kick start the
				work, the right fertilizer and so much more. On the other hand, there are numerous gardeners and plant
				sellers with excellent skills & proficiency, who are ready to share their experiences and knowledge to make
				this world cleaner, greener and happier.
			</p>
			<p className="font-m">
				Sharanyu acts as a bridge between the plant lovers and plant expertise. Through this platform, our
				objective is to connect buyers to sellers and ultimately fill the gap between nature and human beings by
				bringing them closer through green decors.
			</p>
			<p className="font-m">
				Sharanyu is here to answer plant/gardening related queries, raise awareness, connect people, spread
				knowledge and generate employment all at once.
			</p>
			<p className="font-m">
				Sharanyu is the brainchild of Rahul Rao, a computer science engineer from Raipur, Chhattisgarh.
			</p>
			<div className="highlighted">
				<b>
					<span className="font-m">Let's get connected</span>
				</b>
				<br />
				<span>And we promise, your home will be a plant paradise before you know it.</span>
			</div> */}
		</Layout>
	)
}

export default ContactUs
