import React, { useEffect, useState } from "react"
import { ReactComponent as Crown } from "../assets/crown.svg"
import { ReactComponent as Close } from "../assets/cross.svg"
import { MdOutlineModeEdit } from "react-icons/md"
import { IoMdCheckmark } from "react-icons/io"
import axios from "axios"
import Spinner, { spinnerTypes } from "./Spinner"
import { AiOutlineExclamation } from "react-icons/ai"
import ErrorLine from "./ErrorLine"

const headings = ["Get Premium Now", "Payment Successful!", "Payment Failed!"]
const description = [
	"Empower business owners with Sharanyu's premium subscription. Access exclusive features to amplify your online presence.",
	<>
		<p>Congratulations! You are now a premium user.</p>
		<br />
		<b>Please update all GSTINs for all your businesses before listing your production.</b>
	</>,
	"We're sorry, but your payment was unsuccessful. Please try again. If you continue to experience problems, please don't hesitate to contact our support team for assistance."
]
const buttonModes = [
	{
		label: "Buy Premium Now"
	},
	{
		label: "Okay, Great!",
		class: "success"
	},
	{
		label: "Try Again!",
		class: "failed"
	}
]

const GetPremium = ({ context, type = 0 }) => {
	const [inputState, setInputState] = useState()
	const [email, setEmail] = useState()
	const [loading, setLoading] = useState()
	const [errorMessage, setErrorMessage] = useState()

	const close = () => {
		localStorage.removeItem("txnid")
		context.setMasterState(prev => ({ ...prev, premiumModalVisible: false, premium: undefined }))
	}

	useEffect(() => {
		setEmail(context?.masterState?.user?.email || "")
	}, [context])

	const createTransaction = async () => {
		try {
			setLoading(true)
			if (errorMessage) setErrorMessage()
			const response = await axios("/user/get-premium", {
				method: "POST",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				},
				data: { email }
			})

			if (!response.data) throw Error("No data found")

			localStorage.setItem("txnid", response.data.data.txnid)

			const form = document.createElement("form")
			form.action = response.data.url
			form.method = "Post"

			for (const key in response.data.data) {
				const input = document.createElement("input")
				input.type = key === "email" ? "email" : "text"
				input.name = key
				input.value = response.data.data[key]
				form.appendChild(input)
			}

			document.body.appendChild(form)
			form.submit()
		} catch (error) {
			setErrorMessage(error.response.data.error)
			console.log(error)
		}
		setLoading(false)
	}

	const onSubmit = async e => {
		try {
			e.preventDefault()
			if (type === 1) return close()
			createTransaction()
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<div className="overlay">
			<form onSubmit={onSubmit} className="modal" id="get-premium">
				<button type="button" onClick={close}>
					<Close id="close" />
				</button>
				<div id="premium-logo">
					{type === 1 ? (
						<div>
							<IoMdCheckmark size={64} className="payment-success-check" />
						</div>
					) : type === 2 ? (
						<div>
							<AiOutlineExclamation size={64} className="payment-failed-icon" />
						</div>
					) : (
						<Crown />
					)}
				</div>
				<h2 className="subheading">{headings[type]}</h2>
				<div>
					<p className="font-s">{description[type]}</p>
				</div>
				<div className="email-wrapper">
					{type === 0 ? (
						inputState ? (
							<input
								type="email"
								className="theme-input w-fit"
								placeholder="Email"
								name="name"
								value={email}
								onChange={e => setEmail(e.target.value)}
								disabled={loading}
								required={true}
							/>
						) : (
							<div className="flex">
								<div className="theme-input w-fit">
									<span className="font-md">{email}</span>
								</div>
								<button type="button" onClick={() => setInputState(true)} disabled={loading}>
									<MdOutlineModeEdit />
								</button>
							</div>
						)
					) : (
						context?.masterState?.premium?.txnid && (
							<div className="font-s light-font text-center">
								<b>Txn Id:</b> <span>{context?.masterState?.premium?.txnid}</span>
							</div>
						)
					)}
					<div>
						<ErrorLine message={errorMessage} style={{ marginBottom: "10px" }} />
					</div>
				</div>
				<button
					type="submit"
					className={`relative theme-btn primary form-submit ${buttonModes[type]?.class || ""}`}
					disabled={loading}
				>
					{loading && <Spinner type={spinnerTypes.progressBar} {...{ transparent: 1, absolute: 1 }} />}
					{buttonModes[type].label}
				</button>
			</form>
		</div>
	)
}

export default GetPremium
