import React, { useState } from "react"
import { ReactComponent as Arrow } from "../assets/right.svg"
import { ReactComponent as Cross } from "../assets/cross.svg"

const ImageSlider = ({ close, currentPosition, srcs }) => {
	const [slidePosition, setSlidePosition] = useState(currentPosition || 0)
	return (
		<div className="overlay" style={{ background: "black" }}>
			<div className="slider-wrapper">
				<div id="slides-container">
					<div style={{ left: `-${slidePosition * 100}%` }}>
						{srcs?.map(i => (
							<img src={i} alt="plant" />
						))}
					</div>
				</div>
				<div className="dir-btns-wrapper">
					<button onClick={() => setSlidePosition(i => (i > 0 ? i - 1 : 0))}>
						<Arrow style={{ rotate: "180deg" }} />
					</button>
					<button onClick={() => setSlidePosition(i => (i < srcs.length - 1 ? i + 1 : i))}>
						<Arrow />
					</button>
					<button>
						<Cross onClick={close} />
					</button>
				</div>
			</div>
		</div>
	)
}

export default ImageSlider
