import React, { useEffect, useState } from "react"
import Spinner, { spinnerTypes } from "./Spinner"
import { ReactComponent as Cross } from "../assets/cross.svg"
import { generateOTP, verifyOTP } from "../utils/methods"

let resendIvlID = 0
const OTPInput = ({
	size = 6,
	validationPattern = /[0-9]{1}/,
	value = [],
	onChange,
	className,
	phoneNumber,
	changeNumber,
	resend,
	...restProps
}) => {
	const [resendState, setResendState] = useState({ count: 59 })
	useEffect(() => {
		resendIvlID = setInterval(() => {
			if (resendState.count < 1) return clearInterval(resendIvlID)
			setResendState(prev => ({ ...prev, count: prev.count - 1 }))
		}, 1000)

		return () => clearInterval(resendIvlID)
	}, [])

	const resendWrapper = async () => {
		await resend()
		setResendState({ count: 59 })
	}

	const handleKeyUp = e => {
		const current = e.currentTarget
		if (e.key === "ArrowLeft" || e.key === "Backspace") {
			const prev = current.previousElementSibling
			prev?.focus()
			prev?.setSelectionRange(0, 1)
			return
		}

		if (e.key === "ArrowRight") {
			const prev = current.nextSibling
			prev?.focus()
			prev?.setSelectionRange(0, 1)
			return
		}
	}

	const handleInputChange = (e, index) => {
		const val = e.target.value
		if (!validationPattern.test(val) && val !== "") return
		value[index] = val
		onChange(value.slice(0, 6))
		if (val) {
			const next = e.target.nextElementSibling
			next?.focus()
		}
	}

	return (
		<>
			<div className="otp-info">
				<span className="font-xs">Sent OTP on {phoneNumber}</span>
				{changeNumber && (
					<button type="button" className="font-s" onClick={changeNumber} disabled={restProps?.disabled}>
						Change Number?
					</button>
				)}
			</div>
			<div className="flex otp_input">
				{new Array(size).fill("-").map((_, index) => {
					return (
						<input
							key={index}
							type="text"
							{...restProps}
							className={`font-l theme-input outlined ${className || ""}`}
							inputMode="numeric"
							autoComplete="one-time-code"
							placeholder="-"
							value={value.at(index) ?? ""}
							onChange={e => handleInputChange(e, index)}
							onKeyUp={handleKeyUp}
							pattern={validationPattern.source}
							maxLength={1}
						/>
					)
				})}
			</div>
			<div className="otp-info resend">
				<span className="font-xs">Didn't get the OTP?</span>
				{resendState.count < 1 ? (
					<button type="button" className="font-s" onClick={resendWrapper} disabled={restProps?.disabled}>
						Resend
					</button>
				) : (
					<span className="font-xs">
						{" "}
						Resend in
						<span style={{ display: "inline-block", width: "20px" }}>
							{resendState.count.toString().padStart(2, "0")}
						</span>{" "}
						seconds
					</span>
				)}
			</div>
		</>
	)
}

export const OTPModal = ({ phoneNumber, close, onVerified }) => {
	const [OTP, setOTP] = useState()
	const [loading, setLoading] = useState()

	useEffect(() => {
		if (!phoneNumber || phoneNumber?.length !== 10) return
		;(async () => {
			setLoading(true)
			try {
				await generateOTP(phoneNumber)
			} catch (error) {
				console.log(error)
			}
			setLoading(false)
		})()
	}, [phoneNumber])

	const onSubmit = async () => {
		setLoading(true)
		const verified = await verifyOTP(phoneNumber, OTP?.join(""))
		setLoading(false)
		if (verified) {
			onVerified()
			close()
		}
	}

	return (
		<div className="overlay">
			<div className="authPage login">
				<div className="flex between">
					<h2 className="login">Verify Mobile Number</h2>
					<button className="close-btn flex" onClick={close}>
						<Cross />
					</button>
				</div>
				<div>
					<div className="input-fields">
						<OTPInput value={OTP} onChange={setOTP} changeNumber={false} phoneNumber={phoneNumber} />
					</div>
					<button
						type="button"
						className="theme-btn primary relative font-md"
						onClick={onSubmit}
						disabled={loading}
					>
						{loading && <Spinner type={spinnerTypes.progressBar} {...{ absolute: 1, transparent: 1 }} />}
						Verify
					</button>
				</div>
			</div>
		</div>
	)
}

export default OTPInput
