import React, { useEffect, useState } from "react"
import BlurWatcher from "./BlurWatcher"
import { ReactComponent as CheckBox } from "../assets/checkbox.svg"
import { ReactComponent as CheckBoxEmpty } from "../assets/checkboxempty.svg"

const Select = ({
	options,
	onSelect,
	defaultSelection,
	wrapperId,
	IdIcon,
	DropDownIcon,
	placeholder,
	disabled,
	multiselect,
	customInputProps,
	emptyVisible = true,
	filterOptions = true,
	required,
	position = "top"
}) => {
	const [dropDownState, setDropDownState] = useState({ selected: defaultSelection, dropdown: false })
	useEffect(() => {
		setDropDownState(prev => ({ ...prev, selected: defaultSelection }))
	}, [defaultSelection])

	const label =
		(multiselect
			? options
					?.filter(i => dropDownState.selected?.includes(i.value))
					?.map(i => i?.label)
					?.join(", ")
			: options?.find(i => i.value === dropDownState.selected)?.label) || placeholder
	const filteredOptions = filterOptions
		? options?.filter(
				i =>
					!i.disabled &&
					(!dropDownState?.filter?.trim() || i?.label?.toLowerCase()?.includes(dropDownState.filter.toLowerCase()))
		  )
		: options

	const handleSelect = (e, isReset, value) => {
		e.stopPropagation()
		if (isReset) {
			setDropDownState(prev => ({
				...prev,
				selected: []
			}))
			onSelect?.([])
		} else
			setDropDownState(prev => {
				const selected = multiselect
					? (prev?.selected || [])?.includes(value)
						? (prev?.selected || [])?.filter(_i => _i !== value)
						: (prev?.selected || [])?.concat([value])
					: value
				onSelect?.(selected)
				return { selected, dropdown: multiselect }
			})
	}

	return (
		<BlurWatcher
			id={wrapperId}
			className={"select " + position}
			onClick={() => !disabled && setDropDownState(i => ({ ...i, dropdown: !i.dropdown }))}
			onClickOutside={() => setDropDownState(i => ({ ...i, filter: null, dropdown: false }))}
			style={disabled ? { cursor: "not-allowed" } : null}
		>
			<div className="flex relative">
				{IdIcon && <IdIcon />}
				{dropDownState.dropdown ? (
					<input
						type="text"
						value={dropDownState.filter || ""}
						onChange={e => setDropDownState(i => ({ ...i, filter: e.target.value }))}
						autoFocus={true}
						placeholder={label}
						disabled={disabled}
						{...(customInputProps || {})}
					/>
				) : (
					<span style={{ pointerEvents: "none" }} className={!dropDownState.selected ? "no-value" : ""}>
						{label}
					</span>
				)}
				{DropDownIcon && <DropDownIcon />}
				{required && (
					<input
						type="text"
						value={dropDownState?.selected}
						required
						style={{
							pointerEvents: "none",
							position: "absolute",
							zIndex: -1
						}}
					/>
				)}
			</div>
			{dropDownState?.dropdown && (emptyVisible || filteredOptions?.length > 0) && (
				<div className="select-dropdown">
					{filteredOptions?.[0] ? (
						filteredOptions?.map(({ label, value }) => {
							const isReset = ["none", "all"].includes(label.toLowerCase())
							return (
								<button
									type="button"
									className="flex row start gap-m"
									onClick={e => handleSelect(e, isReset, value)}
								>
									{multiselect && (dropDownState?.selected?.includes(value) ? <CheckBox /> : <CheckBoxEmpty />)}
									{label}
								</button>
							)
						})
					) : (
						<em className="font-xs" style={{ textAlign: "center" }}>
							No Match Found
						</em>
					)}
				</div>
			)}
		</BlurWatcher>
	)
}

export default Select
