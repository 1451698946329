import React, { useContext } from "react"
import "./styles.css"
import Navbar from "../../components/Navbar"
import { ReactComponent as CheckMark } from "../../assets/check.svg"
import { ReactComponent as DownArrow } from "../../assets/down.svg"
import Footer from "../../components/Footer"
import { useState } from "react"
import { Link } from "react-router-dom"
import { MasterContext } from "../../context/MasterContext"

// const matrix = [
// 	{ image: "/images/house.png", head: "1000+ Cities", sub: "in India" },
// 	{ image: "/images/plant.png", head: "3 lakh+", sub: "Business listings" },
// 	{ image: "/images/product.png", head: "5 crore+", sub: "monthly orders" }
// ]

const faq = [
	{
		que: "Who can create a business page?",
		ans: "Business owners who own plant nurseries, fertilizer shops, pottery shops, gardening tool shops and seed shops can create a business page."
	},
	{
		que: "Can I add multiple businesses to one profile?",
		ans: "Yes, you can add multiple businesses to one profile."
	},
	{
		que: "Can I sell my products wholesale?",
		ans: "Yes, you can sell your products wholesale."
	},
	{
		que: "Do you provide home delivery services?",
		ans: "If the vendor has registered for home delivery, then yes."
	}
]

const whatDoYouGet = [
	{ head: "Business Partner app", desc: "Manage all your orders on your smartphone with our Android app" },
	{ head: "Business Partner web dashboard", desc: "Manage all your orders on your desktop or laptop" },
	{
		head: "API integration",
		desc: "Manage all your orders on your existing Point of Sale (POS) or third party software"
	}
]

const PartnerWithUs = () => {
	const { masterState, setMasterState } = useContext(MasterContext)
	const [expandedFAQ, setExpandedFAQ] = useState()

	return (
		<div id="partner-with-us" className="pwu">
			<div className="top-content">
				<div className="container flex">
					<Navbar />
					<div className="w-full">
						{masterState?.user?.name && (
							<div className="hero-text welcome-user">
								<span className="font-m">Welcome, {masterState?.user?.name}!</span>
							</div>
						)}
						<div className="hero-text">
							<span>Partner with us,</span>
						</div>
						<div className="hero-text">
							<span>Enroll your business now and reach</span>
						</div>
						<div className="hero-text">
							<span>more customers.</span>
						</div>
						<div id="btns-container">
							<Link
								to={"/business/form"}
								onClick={
									masterState?.token
										? null
										: e => {
												e.preventDefault()
												setMasterState({ login: true })
										  }
								}
								style={{ width: "calc(80% + 5px)" }}
							>
								<button className="theme-btn font-s primary">
									{masterState?.businesses?.[0] ? "Add New business page" : "Create your business page"}
								</button>
							</Link>
							<div>
								<Link to={"/business/form?nature=service"}>
									<button className="theme-btn font-s">Add new service business</button>
								</Link>
								<Link to={"/guide-links"}>
									<button className="theme-btn font-s">Guide links</button>
								</Link>
							</div>
						</div>
						<div>
							<small>
								Need help? Please email us at{" "}
								<a className="anchor-tag" href="mailto:info.sharanyu@gmail.com">
									info.sharanyu@gmail.com
								</a>
							</small>
						</div>
					</div>
				</div>
			</div>
			<div id="gs">
				<div className="container">
					<h2 className="heading">Get started with online ordering</h2>
					<div className="font-s">
						<span>Please keep the documents ready for a smooth signup</span>
					</div>
					<div id="req-docs" className="flex font-m">
						<div>
							<div>
								<CheckMark />
								<span>Image of your business</span>
							</div>
							<div>
								<CheckMark />
								<span>Regular GSTIN(if applicable))</span>
							</div>
							<div>
								<CheckMark />
								<span>Your business menu</span>
							</div>
						</div>
						<div>
							<div>
								<CheckMark />
								<span>PAN card copy</span>
							</div>
							<div>
								<CheckMark />
								<span>Bank account details</span>
							</div>
							<div>
								<CheckMark />
								<span>Valid ID</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="why-we">
				<div className="container">
					<h2 className="heading">Why sharanyu?</h2>
					<div className="font-s">
						<p>
							Sharanyu will enhance the online presence of your business, improve your customer base and increase
							your business's orders which will ultimately result in an increase in your business's revenue.
						</p>
					</div>
					<ul className="font-s">
						<li>We will always be available to assist you.</li>
						<li>Easy signup</li>
						<li>Simplifies business page</li>
						<li>Add multiple businesses</li>
						<li>Easy to interact dashboard to handle all your orders</li>
						<li>Manage via Mobile app and website</li>
					</ul>
					{/*
						<div id="mcards-wrapper" className="flex">
							{matrix?.map(i => (
								<div className="mcard flex">
									<img src={i.image} alt="cities" />
									<div>
										<div className="font-l">
											<span>{i.head}</span>
										</div>
										<div className="font-s">
											<span>{i.sub}</span>
										</div>
									</div>
								</div>
							))}
						</div>
					*/}
				</div>
			</div>
			<div id="on-start">
				<div className="container">
					<h2 className="heading">What do you get on sign-up</h2>
					<div className="font-s">
						<p>
							Sharanyu Partner Platform helps you take your business to new heights instantly with no hassle and
							100% transparency!
						</p>
					</div>
					<div id="steps-wrapper" className="flex">
						<div>
							{whatDoYouGet.map((i, idx) => (
								<div className="step">
									<div>
										<span>{idx + 1}</span>
									</div>
									<div>
										<h2 className="font-l">{i.head}</h2>
										<p className="font-md">{i.desc}</p>
									</div>
								</div>
							))}
						</div>
						<div>
							<img src="/images/screenshot-ex.png" alt="ss" />
						</div>
					</div>
				</div>
			</div>
			<div id="start-now">
				<div className="container">
					<div>
						<h2 className="heading">Start your journey with sharanyu</h2>
						<div className="font-s">
							<span>Create your business page and register for online ordering</span>
						</div>
						<Link
							to={"/business/form"}
							onClick={
								masterState?.token
									? null
									: e => {
											e.preventDefault()
											setMasterState({ login: true })
									  }
							}
						>
							<button className="theme-btn primary">Start Now</button>
						</Link>
					</div>
				</div>
			</div>
			<div id="faq">
				<div className="container">
					<h2>Frequently asked questions</h2>
					{faq.map((i, idx) => (
						<div className="faq" onClick={() => setExpandedFAQ(i => (i === idx ? null : idx))}>
							<div>
								<span className="font-m">{i.que}</span>
								<DownArrow style={{ rotate: expandedFAQ === idx ? "180deg" : "0deg" }} />
							</div>
							{expandedFAQ === idx && (
								<div>
									<span className="font-s">{i.ans}</span>
								</div>
							)}
						</div>
					))}
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default PartnerWithUs
