import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Spinner, { spinnerTypes } from "../../../components/Spinner"
import axios from "axios"

import Layout from "../Layout"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.bubble.css"

const Blog = () => {
	const { blodId } = useParams()
	const [blog, setBlog] = useState()
	const [loading, setLoading] = useState()

	useEffect(() => {
		;(async () => {
			try {
				setLoading(true)
				let response = await axios.get("/blog/" + blodId)
				if (response.data) setBlog(response.data)
			} catch (error) {}
			setLoading()
		})()
	}, [])

	return (
		<Layout id="about-us">
			{blog?.cover && (
				<div id="blog-cover-wrapper">
					<img src={`${axios.defaults.baseURL}/${blog?.cover}`} alt="Cover" />
				</div>
			)}
			<span>
				<small className="font-xs">Created on {new Date(blog?.created_at)?.toDateString()}</small>
			</span>
			<h1 className="master-heading">{blog?.title}</h1>
			<hr />
			<ReactQuill theme="bubble" value={blog?.content} readOnly={true} />
			{loading && <Spinner type={spinnerTypes.spinner} {...{ overlay: true }} />}
		</Layout>
	)
}

export default Blog
