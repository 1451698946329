import React, { useContext } from "react"
import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom"
import "./App.css"
import axios from "axios"
import { MasterContext } from "./context/MasterContext"
import Home from "./pages/home/Page"
import PartnerWithUs from "./pages/partnerWithUs/Page"
import Business, { OrderOnline, Photos, Reviews } from "./pages/business/Page"
import ProductForm from "./pages/productForm/Page"
import Login from "./components/Login"
import Signup from "./components/Signup"
import GetPremium from "./components/GetPremium"
import ProtectedRoutes from "./utils/ProtectedRoutes"
import UserProfile from "./components/UserProfile"
import BusinessForm from "./pages/businessForm/Page"
import AboutUs from "./pages/staticPages/AboutUs"
import Profile from "./pages/staticPages/Profile"
import Vision from "./pages/staticPages/Vision"
import ProductServices from "./pages/staticPages/ProductServices"
import SoilTaxonomy from "./pages/staticPages/SoilTaxonomy"
import PlantsNutritionalRequirement from "./pages/staticPages/PlantsNutritionalRequirement"
import Gallery from "./pages/staticPages/Gallery"
import PremiumRoutes from "./utils/PremiumRoutes"
import Blogs from "./pages/staticPages/blogs/Blogs"
import Blog from "./pages/staticPages/blogs/Blog"
import ApplicationTerms from "./pages/staticPages/ApplicationTerms"
import TermsOfSale from "./pages/staticPages/TermsOfSale"
import GuideLinks from "./pages/guideLinks/Page"
import ContactUs from "./pages/staticPages/ContactUs"

const App = () => {
	return (
		<div id="App">
			<BrowserRouter>
				<Routes>
					<Route element={<RoutesWrapper />}>
						<Route element={<Home />} index />
						<Route element={<PartnerWithUs />} path={"/partner-with-us"} />
						<Route element={<AboutUs />} path={"/about-us"} />
						<Route element={<ContactUs />} path={"/contact-us"} />
						<Route element={<Blogs />} path={"/blogs"} />
						<Route element={<Blog />} path={"/blogs/:blodId"} />
						<Route element={<Profile />} path={"/profile"} />
						<Route element={<Vision />} path={"/vision"} />
						<Route element={<TermsOfSale />} path={"/terms-of-sale"} />
						<Route element={<ApplicationTerms />} path={"/application-terms"} />
						<Route element={<ProductServices />} path={"/product-services"} />
						<Route element={<SoilTaxonomy />} path={"/soil-taxonomy"} />
						<Route element={<PlantsNutritionalRequirement />} path={"/plants-nutritional-requirement"} />
						<Route element={<Gallery />} path={"/gallery"} />
						<Route element={<GuideLinks />} path={"/guide-links"} />

						<Route element={<Business />} path={"business/:business_id"} exact>
							<Route index element={<OrderOnline />} />
							<Route path="reviews" element={<Reviews />} />
							<Route path="photos" element={<Photos />} />
						</Route>

						<Route element={<ProtectedRoutes />}>
							<Route element={<BusinessForm />} path="business/:business_id?/form" exact />
							<Route element={<PremiumRoutes />}>
								<Route element={<ProductForm />} path={"business/:business_id?/product/:product_id?"} exact />
							</Route>
						</Route>

						<Route element={<Navigate to={"/"} />} path={"*"} />
					</Route>
				</Routes>
			</BrowserRouter>
		</div>
	)
}

const RoutesWrapper = () => {
	const context = useContext(MasterContext)
	const submitCreds = async ({ endpoint, credencials }) => {
		const response = await axios.post(endpoint, credencials)
		if (!response.data) return
		const { token } = response.data
		localStorage.setItem("token", token)
		context.fetchInitialData()
	}

	const props = { context, submitCreds }
	const showOverlay = context?.masterState?.login || context?.masterState?.signup || context?.masterState?.profile

	return (
		<React.Fragment>
			<Outlet />
			{context?.masterState?.premiumModalVisible && (
				<GetPremium
					context={context}
					type={
						context?.masterState?.premium?.txnid
							? context?.masterState?.premium?.status === "success"
								? 1
								: 2
							: 0
					}
				/>
			)}
			{showOverlay && (
				<div className="overlay">
					{context?.masterState?.login ? (
						<Login {...props} />
					) : context?.masterState?.signup ? (
						<Signup {...props} />
					) : context?.masterState?.profile ? (
						<UserProfile {...props} />
					) : null}
				</div>
			)}
		</React.Fragment>
	)
}

export default App
