import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import axios from "axios"
import { Provider } from "./context/MasterContext"

axios.defaults.baseURL = "https://api.sharanyu.com"
// axios.defaults.baseURL = "http://localhost:7880"

document.addEventListener("wheel", function (event) {
	if (document.activeElement.type === "number") {
		document.activeElement.blur()
	}
})

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
	<React.StrictMode>
		<Provider>
			<App />
		</Provider>
	</React.StrictMode>
)
