import React from "react"
import { MdErrorOutline } from "react-icons/md"

const ErrorLine = ({ message, ...props }) => {
	return message ? (
		<div className="error-line flex row start gap-m" {...props}>
			<MdErrorOutline className="danger-font" />
			<span className="danger-font font-s">{message}</span>
		</div>
	) : null
}

export default ErrorLine
