import React from "react"
import Layout from "./Layout"

const portalURL = "https://horticulturerejuvenation.com"
const ProductServices = () => {
	return (
		<Layout>
			<h1 className="heading">Product Services</h1>
			<hr />
			<h2 className="subheading">Services</h2>
			<p className="font-m">
				Sharanyu is services/sales oriented web platform to make a online presense of Plant Nurseries, Fertilizers
				shops, Gardeners, Horticulturists and other Horticultural service providers.
			</p>
			<p className="font-m">
				Sharanyu is an exclusive platform for both buyer and seller, connecting business to customer.
			</p>
			<p className="font-m">
				We, at Sharanyu, provide all the information related to plants and are, thus, a solution to any
				Horticultural issue/problem.
			</p>
			<p className="font-m">
				We have listed Plant Nurseries, Fertilizer shops, Tools and accessories shops, Horticulturists and other
				Horticultural service providers.
			</p>
			<p className="font-m">
				The main purpose is encouraging people in planting more plants who would be the customers and providing a
				platform for business organization to generate more employment and profit.
			</p>
			<p className="font-m">
				We believe in having and providing a healthy environment to people and working on social media platform to
				create awareness about environment and problem solving solutions regarding plants.
			</p>

			<h2 className="subheading">Products</h2>
			<hr />
			<p className="font-m">
				We the Viruty Sharanyu Pvt. Ltd. is not at all selling or buying any products through our{" "}
				<a className="redirect-anchor" href={portalURL}>
					web portal
				</a>
				. Our main role is as a service provider not a product seller or buyer.
			</p>
			<p className="font-m">Sharanyu is an intermediary between buyer and seller.</p>
			<p className="font-m">
				We are personally not selling any product; we are making transactions on behalf of business vendors.
			</p>

			<h2 className="subheading">Advertisement</h2>
			<hr />
			<p className="font-m">
				We also provide an opportunity for you to advertise your business on our website. We will take
				advertisements via Advertisement through us. Advertisements are only available for business vendors, whose
				business feed has been approved by us.
			</p>
			<p className="font-m">
				Advertisment section is our premium part which is under the <b>"Paid Service"</b> where the people can get
				the graphical advertisement in our web portal. The paid advertisement is only deal with{" "}
				<b>Indian Rupees (INR)</b> and we categorized it into 3 types of duration which is for{" "}
				<b>01 Month, 03 Months and 06 Months</b>.
			</p>

			<h2 className="subheading">Membership</h2>
			<hr />
			<p className="font-m">
				The membership or registration process is totally <b>"Free of Cost"</b> in our{" "}
				<a className="redirect-anchor" href={portalURL}>
					web portal
				</a>
				. The benefit of the membership through our web portal is to promote all the vendors related to plant
				nurseries, fertilizer shop, gardener and horticulture experts who can publish their company details,
				products with images and contact details.
			</p>
		</Layout>
	)
}

export default ProductServices
