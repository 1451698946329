import React from "react"

export const spinnerTypes = {
	progressBar: "progress-bar",
	pulse: "pulse",
	spinner: "spinner"
}

const Spinner = ({ overlay, absolute, type, transparent }) => {
	return (
		<div
			className={`spinner-wrapper flex ${overlay ? "overlay" : ""} ${absolute ? "absolute" : ""} ${
				transparent ? "transparent" : ""
			}`}
		>
			<span className={"loader " + type} />
		</div>
	)
}

export default Spinner
