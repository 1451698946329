import React, { useState, useContext, useEffect } from "react"
import Navbar from "../../components/Navbar"
import Footer from "../../components/Footer"

import axios from "axios"
import { nanoid } from "nanoid"
import { MasterContext } from "../../context/MasterContext"
import { ReactComponent as Manage } from "../../assets/settings.svg"
import { ReactComponent as Add } from "../../assets/add.svg"
import { ReactComponent as Cross } from "../../assets/cross.svg"
import Spinner, { spinnerTypes } from "../../components/Spinner"
import { FaRegUserCircle, FaStar } from "react-icons/fa"
import { FaLink } from "react-icons/fa6"

const GuideLinks = () => {
	const { masterState } = useContext(MasterContext)
	const [guideLinks, setGuideLinks] = useState([])
	const [flags, setFlags] = useState({})

	useEffect(() => {
		;(async () => {
			try {
				setFlags({ loading: true })
				const response = await axios.get("/user/guide-links")
				setGuideLinks(response?.data)
			} catch (error) {
				console.log(error)
			} finally {
				setFlags()
			}
		})()
	}, [])

	const handleSave = (updatedLinks, removed) => {
		setGuideLinks(prev =>
			prev
				?.filter(i => !removed?.includes(i._id) && !updatedLinks?.find(_i => _i?._id === i._id))
				?.concat(updatedLinks)
				?.sort((a, b) => b.created_at - a.created_at)
		)
		setFlags()
	}

	return (
		<div id="product-form" className="page-wrapper">
			<Navbar />
			<div className="container">
				<div className="flex between">
					<div>
						<h1 className="heading">Guide Links</h1>
						<span className="font-xs">
							Here you can explore guidance links such as website blogs, social media, youtube chennels and much
							more.
						</span>
					</div>

					{masterState?.token && (
						<div className="flex">
							<button className="theme-btn outlined flex" onClick={() => setFlags({ manage: true })}>
								<Manage width={"22px"} height={"22px"} /> Manage Your Links
							</button>
						</div>
					)}
				</div>
				<div className="urls-wrapper">
					<div className="content">
						{guideLinks?.map(i => (
							<div className="guide-link" key={i._id}>
								<div className="flex row start">
									<FaRegUserCircle size={20} />
									<span>{i?.name}</span>
									{i.premium && <FaStar size={20} />}
								</div>
								<div className="flex row start">
									<FaLink size={20} />
									<a href={i.url} target="_blank" rel="noopener noreferrer">
										<span
											className="text-ellipse"
											style={{ "whiteSpace": "break-spaces", "--lines": 1, "fontFamily": "monospace" }}
										>
											{i.url}
										</span>
									</a>
								</div>
								<p>{i.description}</p>
							</div>
						))}
					</div>
				</div>
			</div>
			<Footer />
			{flags?.manage && <ManageGuideLinks close={() => setFlags()} onSave={handleSave} />}
		</div>
	)
}

const ManageGuideLinks = ({ close, onSave }) => {
	const { masterState } = useContext(MasterContext)
	const [guideLinks, setGuideLinks] = useState([])
	const [loading, setLoading] = useState()
	const [removedLinks, setRemovedLinks] = useState([])

	useEffect(() => {
		;(async () => {
			try {
				setLoading(true)
				const response = await axios.get("/user/user-guide-links", {
					headers: {
						Authorization: `Bearer ${masterState?.token}`
					}
				})
				setGuideLinks(response?.data)
			} catch (error) {
				console.log(error)
			} finally {
				setLoading()
			}
		})()
	}, [])

	const onSubmit = async () => {
		setLoading(true)
		try {
			const filteredGuideLinks = guideLinks?.filter(i => i.url)
			const response = await axios.patch(
				"/user",
				{
					guide_links: filteredGuideLinks?.map(i => (i.new ? { ...i, _id: undefined } : i))
				},
				{
					headers: {
						Authorization: `Bearer ${masterState.token}`
					}
				}
			)
			if (response.data) {
				onSave(
					filteredGuideLinks?.map((i, index) => ({
						...i,
						name: masterState?.user?.name,
						premium: Boolean(masterState?.premium?.active)
					})),
					removedLinks?.concat(guideLinks?.filter(i => !i.url && !i.new)?.map(i => i._id))
				)
			}
		} catch (error) {
			alert("Failed to save guide links")
		}
		setLoading(false)
	}

	const handleRemove = (id, isNew) => {
		console.log({ id, isNew })
		if (!isNew) setRemovedLinks(prev => prev.concat(id))
		setGuideLinks(prev => prev.filter(u => u._id !== id))
	}

	return (
		<div className="overlay">
			<div className="section modal">
				<div className="flex between">
					<div>
						<h1 className="heading">Your Guide Links</h1>
						<span className="font-xs">Share your website, social media or youtube chennel here with us.</span>
					</div>

					<div className="flex">
						<button
							className="theme-btn outlined flex"
							onClick={() =>
								setGuideLinks(prev => [
									{
										_id: nanoid(12),
										url: "",
										created_at: Date.now(),
										new: true
									},
									...prev
								])
							}
						>
							<Add /> New Link
						</button>
						<button onClick={close}>
							<Cross width={20} />
						</button>
					</div>
				</div>
				<div className="urls-wrapper">
					<div className="content">
						{guideLinks?.map(({ _id, url, description, mew: isNew }) => (
							<div className="theme-input">
								<div className="input-action-wrapper">
									<input
										key={_id}
										type="text"
										className="font-s"
										defaultValue={""}
										value={url}
										style={{ paddingRight: "26px" }}
										onChange={e =>
											setGuideLinks(prev => prev.map(u => (u._id === _id ? { ...u, url: e.target.value } : u)))
										}
										placeholder="URL"
									/>
									<button tabIndex="-1" onClick={() => handleRemove(_id, isNew)}>
										<Cross />
									</button>
								</div>
								<hr />
								<textarea
									key={_id}
									type="text"
									className="font-s"
									defaultValue={""}
									value={description}
									onChange={e =>
										setGuideLinks(prev =>
											prev.map(u => (u._id === _id ? { ...u, description: e.target.value } : u))
										)
									}
									placeholder="Description"
								/>
							</div>
						))}
					</div>
				</div>
				<div className="flex">
					<button className="theme-btn primary form-submit relative" onClick={onSubmit}>
						{loading && <Spinner type={spinnerTypes.progressBar} {...{ transparent: 1, absolute: 1 }} />}
						Save
					</button>
				</div>
			</div>
		</div>
	)
}

export default GuideLinks
