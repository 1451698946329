import axios from "axios"
import { useEffect, useState } from "react"
import { createContext } from "react"
export const MasterContext = createContext({})

export const Provider = ({ children }) => {
	const [masterState, setMasterState] = useState({})
	const [notificationState, setNotificationState] = useState()

	const notify = (data, ms = 3500) => {
		setNotificationState(data)
		setTimeout(() => setNotificationState(), ms)
	}

	const fetchInitialData = async () => {
		try {
			const initialState = {
				token: localStorage.getItem("token") || null,
				businesses: null,
				user: {
					email: null,
					name: null
				}
			}

			let data = {}
			if (initialState.token) {
				const response = await axios.get("/user/profile", {
					headers: { Authorization: `Bearer ${initialState.token}` }
				})
				if (response.data) {
					const { businesses, services, guide_links, premium, ...user } = response.data
					data = {
						user,
						businesses,
						services,
						guide_links,
						...(premium ? { premium: { active: premium } } : {})
					}
				}
			}

			const txnid = localStorage.getItem("txnid")
			if (txnid)
				try {
					const response = await axios.get("/transaction", {
						params: { txnid },
						headers: {
							Authorization: `Bearer ${initialState.token}`
						}
					})

					if (response?.data) data = { ...data, premiumModalVisible: true, premium: response.data }
				} catch (error) {
					console.log(error)
				}

			const [categories, cities] = await Promise.all([axios.get("/category/all"), axios.get("/city")])
			data = { ...data, categories: categories.data, cities: cities.data }

			setMasterState({ ...initialState, ...data })
		} catch (error) {
			console.log({ error: error.message })
		}
	}

	useEffect(() => {
		fetchInitialData()
	}, [])

	return (
		<MasterContext.Provider
			value={{ masterState, setMasterState, fetchInitialData: fetchInitialData, notificationState, notify }}
		>
			{children}
		</MasterContext.Provider>
	)
}
