import React from "react"
import { BiSolidPin } from "react-icons/bi"
import Layout from "./Layout"

const AboutUs = () => {
	const points = [
		<span className="font-m">Plants inspire us & make us believe in tomorrow.</span>,
		<span className="font-m">Plants help clean our environment.</span>,
		<span className="font-m">Plant makes us happy.</span>,
		<span className="font-m">Plants are the best gift to give someone.</span>,
		<span className="font-m">Plants make a home look mesmerizing.</span>,
		<span className="font-m">Plants help in keeping us calm.</span>,
		<span className="font-m">Planting is a fun hobby.</span>,
		<span className="font-m">Plants are the best therapists</span>
	]

	return (
		<Layout id="about-us">
			<h1 className="heading">About Us</h1>
			<hr />
			<div className="flex col start gap-m font-m">
				{points?.map(i => (
					<div className="flex row start gap-m">
						<BiSolidPin className="theme-font font-l" style={{ rotate: "-30deg" }} />
						{i}
					</div>
				))}
			</div>
			<div className="font-m highlighted">
				<p className="font-m">
					We can go on and on and talk about our favourite subject endlessly. Afterall, it is our true love, strong
					passion and firm commitment towards plants that made us come up with <b>Sharanyu</b>.
				</p>
			</div>
			<h2 className="subheading">WHY SHARANYU?</h2>
			<hr />
			<p className="font-m">
				We noticed that gardening does interest people of all ages who truly believe that gardening is beautiful,
				meditative, healthy, exciting, rewarding and creative work. But what they lack is a deep understanding and
				accurate information about plants, how to sow different seeds, grow seasonal, air purifier plants & most
				importantly the remedy to maintain them. People also lack the knowledge to choose the right plant, the
				right soil and at the right weather, accessibility to buy proper tools and accessories to kick start the
				work, the right fertilizer and so much more. On the other hand, there are numerous gardeners and plant
				sellers with excellent skills & proficiency, who are ready to share their experiences and knowledge to make
				this world cleaner, greener and happier.
			</p>
			<p className="font-m">
				Sharanyu acts as a bridge between the plant lovers and plant expertise. Through this platform, our
				objective is to connect buyers to sellers and ultimately fill the gap between nature and human beings by
				bringing them closer through green decors.
			</p>
			<p className="font-m">
				Sharanyu is here to answer plant/gardening related queries, raise awareness, connect people, spread
				knowledge and generate employment all at once.
			</p>
			<p className="font-m">
				Sharanyu is the brainchild of Rahul Rao, a computer science engineer from Raipur, Chhattisgarh.
			</p>
			<div className="highlighted">
				<b>
					<span className="font-m">Let's get connected</span>
				</b>
				<br />
				<span>And we promise, your home will be a plant paradise before you know it.</span>
			</div>
		</Layout>
	)
}

export default AboutUs
