import React, { useMemo } from "react"
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api"
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete"
import Select from "./Select"
import { ReactComponent as Down } from "../assets/down.svg"
const libraries = ["places"]

const Places = props => {
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: "AIzaSyD5UrRJa3EE14yYCI0gQvYH555a7vxNcQo",
		libraries
	})

	if (!isLoaded) return <div>Loading...</div>
	return <PlacesAutocomplete {...props} />
}

const PlacesAutocomplete = ({ selected, setSelected }) => {
	const center = useMemo(() => ({ lat: 26.9124, lng: 75.7873 }), [])
	const {
		ready,
		value,
		setValue,
		suggestions: { data },
		clearSuggestions
	} = usePlacesAutocomplete({
		debounce: 300
	})

	const handleSelect = async address => {
		setValue(address, false)
		clearSuggestions()
		const results = await getGeocode({ address })
		const { lat, lng } = getLatLng(results[0])
		setSelected({ lat, lng, googlemap_placeid: results?.[0]?.place_id })
	}

	const onDragEnd = e => {
		if (!e.placeId) return
		const { lat, lng } = e.latLng
		setValue(null, false)
		setSelected({
			googlemap_placeid: e.placeId,
			lat: lat(),
			lng: lng()
		})
	}

	return (
		<>
			<div>
				<Select
					DropDownIcon={() => <Down opacity={".7"} />}
					customInputProps={{
						value: value,
						onChange: e => setValue(e.target.value)
					}}
					options={data.map(({ description }) => ({
						label: description,
						value: description
					}))}
					placeholder={value || "Search your business location"}
					defaultSelection={data.product_types}
					onSelect={handleSelect}
					disabled={!ready}
					emptyVisible={false}
					filterOptions={false}
				/>
				<small className="font-xs">
					Make sure your business is accurately located on Google Maps. This will help calculate delivery distance
					and cost for orders.
				</small>
			</div>
			<GoogleMap
				zoom={selected?.lat ? 15 : 10}
				center={selected || center}
				onClick={onDragEnd}
				options={{
					clickableIcons: true,
					streetViewControl: false
				}}
				mapContainerStyle={{
					position: "relative",
					aspectRatio: 5 / 3
				}}
			>
				{selected?.lng ? <Marker position={selected} /> : null}
			</GoogleMap>
		</>
	)
}

export default Places
