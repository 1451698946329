import React, { useEffect } from "react"
import { useRef } from "react"

const BlurWatcher = ({ children, onClickOutside, ...props }) => {
	const ref = useRef()

	useEffect(() => {
		const blur = e => {
			if (ref.current && !ref.current.contains(e.target)) onClickOutside()
		}
		document.addEventListener("click", blur)
		return () => document.removeEventListener("click", blur)
	})

	return (
		<div tabIndex={1} ref={ref} {...props}>
			{children}
		</div>
	)
}

export default BlurWatcher
