import React from "react"
import Layout from "./Layout"

const Vision = () => {
	return (
		<Layout>
			<h1 className="heading">Vision</h1>
			<hr />
			<p className="quote flex">
				<q>Be the change you want to see in the World.</q>
				{" - "}
				<b>Mahatma Gandhi</b>
			</p>
			<p className="font-m">
				Our vision is to see the world turning into a green paradise, free of pollution & full of love and be the
				number one solution provider when it comes to anything related to plants.
			</p>
			<h2 className="subheading">Mission</h2>
			<p className="font-m">
				We are on a mission to act as a bridge between plant buyers and sellers and also to provide a platform for
				plant lovers to enhance their skills & knowledge about the thing they love the most- gardening, by sharing
				authentic and most reliable information.
			</p>
		</Layout>
	)
}

export default Vision
