import React from "react"
import { BiSolidPin } from "react-icons/bi"
import Layout from "./Layout"

const SoilTaxonomy = () => {
	const soils = [
		<span className="font-md">Inceptisols (39.74%)</span>,
		<span className="font-md">Entisols (28.08%)</span>,
		<span className="font-md">Alfisols (13.55%)</span>,
		<span className="font-md">Vertisols (9.52%)</span>,
		<span className="font-md">Ardisols (4.28%)</span>,
		<span className="font-md">Ultisols (2.51%)%</span>,
		<span className="font-md">Mollisols (0.40%)</span>,
		<span className="font-md">Others (2.92%)</span>
	]

	const soildDetails = {
		inceptisols: [
			<span className="font-m">They form quickly through Parent material.</span>,
			<span className="font-m">They are more developed than entisols.</span>,
			<span className="font-m">
				Soil profile contains accumulation of clay, iron oxide, aluminum oxide and organic matter.
			</span>
		],

		entisols: [
			<span className="font-m">It occupies 16% of the earth surface largest after inceptisols.</span>,
			<span className="font-m">They are more of deposited soil, Alluvium.</span>,
			<span className="font-m">Generally fertile in nature.</span>
		],

		alfisols: [
			<span className="font-m">Alfisols form in semi-arid to humus area.</span>,
			<span className="font-m">Occupies 10% of earth surface ice freeland.</span>,
			<span className="font-m">Calcium, Magnesium and Potassium are abundant. (Aluminum and iron also).</span>,
			<span className="font-m">They are highly productive soil.</span>
		],

		vertisols: [
			<span className="font-m">This soil shrinks when dry and swells when wet.</span>,
			<span className="font-m">
				It is also known as Black Earth Soil in Australia, Black Gumbo in East Texas and Black Cotton Soil in East
				Africa and India.
			</span>,
			<span className="font-m">Colour of soil varies from grey to deep black.</span>,
			<span className="font-m">It's parent material is basalt.</span>
		],

		ardisols: [
			<span className="font-m">This Soil forms in arid and semi-arid climate.</span>,
			<span className="font-m">Low in organic content.</span>,
			<span className="font-m">Lime and salt are common in subsurface Horizon.</span>,
			<span className="font-m">
				Productivity of this soil is low. Productivity can be raised by proper irrigation and use of fertilizer.
			</span>
		],

		ultisols: [
			<span className="font-m">Ultisols are intensively weathered soil of warm and humid climate.</span>,
			<span className="font-m">Commonly known as Red clay soil.</span>,
			<span className="font-m">
				Ultisols are generally low in fertility. Can become productive by the addition of fertilizer.
			</span>,
			<span className="font-m">Ultisols are generally acidic.</span>
		],

		mollisols: [
			<span className="font-m">Mollisols form in semi-arid to semi-humid areas. Typically under grassland.</span>,
			<span className="font-m">
				Topsoil of Mollisol is characteristically dark and rich with organic matter giving it a lot of natural
				fertility.
			</span>,
			<span className="font-m">Considered as most fertile soil on earth.</span>
		]
	}

	const otherSoils = [
		<span className="font-md">Alluvial Soil</span>,
		<span className="font-md">Black Soil</span>,
		<span className="font-md">Red and yellow Soil</span>,
		<span className="font-md">Laterite Soil</span>,
		<span className="font-md">Arid Soil</span>,
		<span className="font-md">Saline Soil</span>,
		<span className="font-md">Peaty Soil</span>,
		<span className="font-md">Forest Soil</span>
	]

	const soilDesc = {
		"alluvial soil":
			"They are deposited soil. Transported and deposited by rivers and streams. Alluvial soil vary in nature from sandy loam to clay. They are generally rich in potash but poor in phosphorous. The color of alluvial soil varies from light grey to ash grey. Alluvial soil are highly productive.",
		"black soil":
			"Black soil are also known as regur soil and black cotton soil. They swell and become sticky when wet and shrinks when dry. Black soil are rich in lime, iron, magnesia and aluminum. They are also contain potash. but they are lack in phosphorous, nitrogen and organic matter.",
		"red and yellow soil":
			"Red soil develops in crystalline igneous rocks. The fine grain red yellow soil are generally fertile. Coarse grained soil are poor in fertility. They are generally poor in nitrogen, phosphorous and humas(organic matter).",
		"laterite soil":
			"Laterite soil developed in area with high temperature and high rain fall. Soil is rich in iron oxide, aluminum and potassium. Soil is poor in organic matter, nitrogen, phosphorous and calcium. Application of manure and fertilizer are required for making soil fertile for cultivation.",
		"arid soil":
			"It is found at dry area with high temperature. The color of this soil ranges from red to brown. Most of the arid soil are sandy and saline. Phosphate content are normal and nitrogen are deficit. Containing humas and organic matter",
		"saline soil":
			"Contains large amount of sodium, potassium and magnesium. They occur at arid and semi-arid region. Their structure vary from sandy to loamy. They are lack in nitrogen and calcium. Gypsum is used to solve the problem of salinity in soil.",
		"peaty soil":
			"Peaty soil are also known as marshy soil. Found in the area of heavy rainfall and high humidity. Provides good growth of vegetation as they are very rich in fertility. It contains very large quantity of organic matter which is also called humus. which contributes to alkalinity. These soil are usually heavy and black in colour.",
		"forest soil":
			"As name suggesting itself they are found in forest area where sufficient rainforest is available. The texture of the soil depending upon the environment. Its quality of being fertile also varies depending upon the environment; mountain, valleys and slopes."
	}

	return (
		<Layout>
			<h1 className="heading">Soil Taxonomy</h1>
			<hr />
			<p className="font-m">
				Indian council of agriculture and research(ICAR) has classified Indian soil at international level on the
				basis of their nature and character as per united states Department of Agriculture(USDA) soil taxonomy.
				Those are:
			</p>
			<div className="flex col start gap-m font-m">
				{soils?.map(i => (
					<div className="flex row start gap-m">
						<BiSolidPin className="theme-font font-l" style={{ rotate: "-30deg", minWidth: "22px" }} />
						{i}
					</div>
				))}
			</div>
			{Object.keys(soildDetails).map(i => (
				<>
					<h2 className="subheading">{i}</h2>
					<hr />
					<div className="flex col start gap-m font-m">
						{soildDetails[i]?.map(_i => (
							<div className="flex row start gap-m">
								<BiSolidPin className="theme-font font-l" style={{ rotate: "-30deg", minWidth: "22px" }} />
								{_i}
							</div>
						))}
					</div>
				</>
			))}
			<br />
			<p className="font-m">On the basis of Genesis, color, composition and location the soil of india has been classified into:</p>
			<div className="flex col start gap-m font-m">
				{otherSoils?.map(i => (
					<div className="flex row start gap-m">
						<BiSolidPin className="theme-font font-l" style={{ rotate: "-30deg", minWidth: "22px" }} />
						{i}
					</div>
				))}
			</div>

			{Object.keys(soilDesc).map(i => (
				<>
					<h2 className="subheading">{i}</h2>
					<hr />
					<p className="font-m">{soilDesc[i]}</p>
				</>
			))}
		</Layout>
	)
}

export default SoilTaxonomy
