import React, { useEffect } from "react"
import { useState } from "react"
import axios from "axios"
import ImageSlider from "../../components/ImageSlider"
import Layout from "./Layout"
import { IoAlbumsOutline } from "react-icons/io5"
import { IoMdArrowBack } from "react-icons/io"

const Gallery = () => {
	const [albums, setAlbums] = useState()
	const [sliderState, setSliderState] = useState()
	const [viewAlbum, setViewAlbum] = useState()

	useEffect(() => {
		;(async () => {
			try {
				const response = await axios.get("/admin/gallery")
				if (response.data)
					setAlbums(
						response.data.map(album => ({
							...album,
							images: album.images?.map(i => ({
								...i,
								url: `${axios.defaults.baseURL}/${i.path}`
							}))
						}))
					)
			} catch (error) {}
		})()
	}, [])

	return (
		<Layout id="gallery-page">
			<div>
				<h1 className="heading">Gallery</h1>
			</div>

			<div id="gallery-content">
				{viewAlbum?._id ? (
					<div className="album-view">
						<button className="flex back-btn" onClick={() => setViewAlbum()}>
							<IoMdArrowBack />
							<span>Back</span>
						</button>
						<h4 className="heading">{viewAlbum?.title}</h4>
						<p className="font-s">{viewAlbum?.description}</p>
						<h6 className="subheading">Images</h6>
						<div className="grid">
							{viewAlbum?.images?.map((i, idx) => (
								<button className="image-container" key={i.name} onClick={() => setSliderState(idx)}>
									<img src={i.url} alt={i.name} />
								</button>
							))}
						</div>
					</div>
				) : (
					<div id="albums-container" className="flex row start wrap">
						{albums?.map(i => (
							<div key={i._id} className="album flex col no-gap start">
								<button className="image-container cover-image relative" onClick={() => setViewAlbum(i)}>
									<img src={i.images[0]?.url} alt="album cover" />
									<IoAlbumsOutline className="font-xl album-icon" />
								</button>
								<div className="album-content">
									<div>
										<div>
											<span className="font-l text-ellipse" style={{ "maxHeight": "2rem", "--lines": 1 }}>
												{i.title}
											</span>
										</div>
										<div>
											<span className="font-s text-ellipse">{i.description}</span>
										</div>
										<div>
											<span className="font-s">Images: {i.images.length}</span>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				)}
			</div>

			{typeof sliderState === "number" && (
				<ImageSlider
					close={() => setSliderState()}
					currentPosition={sliderState}
					srcs={viewAlbum?.images?.map(i => i.url)}
				/>
			)}
		</Layout>
	)
}

export default Gallery
