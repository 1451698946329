import React, { useEffect, useState } from "react"
import { ReactComponent as Cross } from "../assets/cross.svg"
import NumberInput from "./MobileInput"
import OTPInput from "./OTPInput"
import Spinner, { spinnerTypes } from "./Spinner"
import axios from "axios"
import { generateOTP, verifyOTP } from "../utils/methods"

const UserProfile = ({ context }) => {
	const { masterState, setMasterState } = context
	const [data, setData] = useState({})
	const [OTPState, setOTPState] = useState()
	const [loading, setLoading] = useState()

	useEffect(() => {
		;(async () => {
			try {
				const response = await axios.get("/user/profile", {
					headers: {
						Authorization: `Bearer ${masterState.token}`
					}
				})

				setData({
					...response.data,
					verifiedNumber: response.data?.phone_number
				})
			} catch (error) {
				console.error(error)
			}
		})()
	}, [masterState])

	const onChange = e => setData(prev => ({ ...prev, [e.target.name]: e.target.value }))
	const generateOTPWrapper = async () => {
		try {
			setOTPState({ visible: true })
			await generateOTP(data?.phone_number)
			setOTPState(prev => ({ ...prev, generated: true }))
		} catch (error) {
			console.log(error)
		}
	}

	const verifyOTPWrapper = async () => {
		try {
			const verified = await verifyOTP(data?.phone_number, OTPState?.value?.join(""))
			if (verified) await saveProfile()
		} catch (error) {
			console.log(error)
		}
	}

	const saveProfile = async () => {
		try {
			const { password, ...payload } = data

			await axios.patch("/user", payload, {
				headers: {
					Authorization: `Bearer ${masterState.token}`
				}
			})

			setMasterState(prev => ({ ...prev, user: payload, profile: false }))
		} catch (error) {
			console.log(error)
		}
	}

	const onSubmit = async e => {
		setLoading(true)
		try {
			if (e) e.preventDefault()
			if (data?.phone_number?.length === 10 && data?.phone_number !== data?.verifiedNumber) {
				if (!OTPState?.generated) await generateOTPWrapper()
				else await verifyOTPWrapper()
			} else saveProfile()
		} catch (error) {
			console.error(error)
		}
		setLoading(false)
	}

	return (
		<div id="user-profile" className="authPage login">
			<div className="flex start between">
				<h2 className="login">Profile</h2>
				<button className="close-btn flex" onClick={() => setMasterState(prev => ({ ...prev, profile: false }))}>
					<Cross />
				</button>
			</div>
			<form onSubmit={onSubmit}>
				<div className="input-fields">
					{OTPState?.visible ? (
						<OTPInput
							value={OTPState?.value}
							onChange={value => setOTPState(prev => ({ ...prev, value }))}
							phoneNumber={data?.phone_number}
							changeNumber={() => setOTPState({})}
						/>
					) : (
						<>
							<input
								type="text"
								className="theme-input"
								placeholder="Full Name"
								name="name"
								defaultValue={""}
								value={data.name}
								onChange={onChange}
								required={true}
								disabled={loading}
							/>
							<input
								type="email"
								className="theme-input"
								placeholder="Email"
								name="email"
								defaultValue={""}
								value={data.email}
								onChange={onChange}
								disabled={loading}
							/>
							<NumberInput
								verify={data?.phone_number?.length === 10 && data?.phone_number !== data?.verifiedNumber}
								name="phone_number"
								value={data.phone_number}
								onChange={onChange}
								verifyOTP={() => onSubmit()}
								disabled={loading}
							/>
							<input
								type="text"
								className="theme-input"
								placeholder="Address"
								name="address"
								defaultValue={""}
								value={data.address}
								onChange={onChange}
								disabled={loading}
							/>
							<div className="font-xs" style={{ marginBottom: "10px" }}>
								Your orders will be delivered to this address.
							</div>
						</>
					)}
				</div>
				<button type="submit" className="relative theme-btn primary font-md" disabled={loading}>
					{loading && <Spinner type={spinnerTypes.progressBar} {...{ absolute: 1, transparent: 1 }} />}
					{OTPState?.visible ? "Verify" : "Save"}
				</button>
			</form>
		</div>
	)
}

export default UserProfile
