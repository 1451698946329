import React, { useState, useContext, useCallback } from "react"
import { Link, NavLink, useLocation } from "react-router-dom"
import { MdOutlineArrowDropDown } from "react-icons/md"
import MasterSearch from "./MasterSearch"
import { ReactComponent as MenuIcon } from "../assets/menu.svg"
import { ReactComponent as Cross } from "../assets/cross.svg"
import { MasterContext } from "../context/MasterContext"
import { getLinks } from "../utils/methods"
import Prompt from "./Prompt"

const authActions = [
	{ label: "Login", actionValue: { login: true }, auth: true },
	{ label: "Signup", actionValue: { signup: true }, auth: true },
	{ label: "My Profile", profile: true },
	{ label: "Log Out", logout: true }
]

const Navbar = ({ hideLogo, showSearchBar, hideLinks, hideActions }) => {
	const { masterState, setMasterState } = useContext(MasterContext)
	const { pathname } = useLocation()
	const [menuState, setMenuState] = useState()
	const [promptState, setPromptState] = useState()
	const [switches, setSwitches] = useState({ businesses: false })

	const LinksActions = useCallback(() => {
		const links = !hideLinks ? getLinks(masterState, pathname) : []
		return (
			<>
				{links?.map(i =>
					i?.id === "mb" ? (
						masterState?.businesses?.length > 1 ? (
							<div key={i.id} className="navlink-dropdown">
								<button
									className="trigger"
									onClick={() => setSwitches(prev => ({ businesses: !prev?.businesses }))}
								>
									<span className="font-s">{i.label}</span>
									<MdOutlineArrowDropDown
										className="font-m"
										style={{ rotate: switches?.businesses ? "180deg" : "" }}
									/>
								</button>
								{switches?.businesses && (
									<div className="dropdown">
										{masterState?.businesses?.map(business => (
											<NavLink className={"font-s"} to={`/business/${business?.id}`}>
												{business?.title}
											</NavLink>
										))}
										<NavLink className={"font-s"} to={`/partner-with-us`}>
											+ Add New Business
										</NavLink>
									</div>
								)}
							</div>
						) : (
							masterState?.businesses?.[0] && (
								<NavLink className={"font-s"} key={i.id} to={`/business/${masterState?.businesses?.[0]?.id}`}>
									{i.label}
								</NavLink>
							)
						)
					) : (
						<NavLink className={"font-s"} key={i.id} to={i.path}>
							{i.label}
						</NavLink>
					)
				)}
				{!hideActions &&
					authActions.map(i =>
						(masterState?.token ? !i.auth : i.auth) ? (
							<button
								key={i.label}
								className={"font-s"}
								onClick={() => {
									if (i.logout) return setPromptState({ logout: true })
									else if (i.profile) return setMasterState(prev => ({ ...prev, profile: true }))
									i.action?.()
									setMasterState(i.actionValue)
								}}
							>
								{i.label}
							</button>
						) : (
							""
						)
					)}
			</>
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hideLinks, hideActions, masterState, switches])

	const logout = () => {
		localStorage.clear()
		setPromptState()
		setMasterState()
	}

	return (
		<nav>
			<div>
				{!hideLogo && (
					<Link to={"/"}>
						<img src="/images/logo.png" alt="Sharanyu" />
					</Link>
				)}
			</div>
			{showSearchBar && <MasterSearch />}
			<div id="navlinks-container">
				<LinksActions />
			</div>
			<div id="hbmenu-wrapper">
				<div onClick={() => setMenuState(i => !i)}>
					<button id="mtoggle-btn">{menuState ? <Cross /> : <MenuIcon />}</button>
				</div>
				{menuState && (
					<div id="mwrpr">
						<LinksActions />
					</div>
				)}
			</div>
			{promptState?.logout && (
				<Prompt
					heading="Logout"
					message="Are you sure you want to log out?"
					Actions={() => (
						<>
							<button className="theme-btn grey" onClick={() => setPromptState(false)}>
								Cancel
							</button>
							<button className="theme-btn danger" onClick={logout}>
								Logout
							</button>
						</>
					)}
				/>
			)}
		</nav>
	)
}

export default Navbar
