import React from "react"
import Layout from "./Layout"

const ApplicationTerms = () => {
	return (
		<Layout>
			<h1 class="heading">APPLICATION TERMS & CONDITIONS OF SERVICE</h1>

			<h2 class="subheading">General Terms</h2>
			<hr />
			<p class="font-m">
				These terms and conditions ("Agreement") set forth the general terms and conditions of your use of the
				"Sharanyu (Viruty Horticulture Rejuvenation Private Limited)" Mobile Application/website ("Mobile
				Application/website" or "Service") and any of its related products and services (collectively, "Services").
				This Agreement is legally binding between you ("User", "you" or "your") and this Mobile Application/website
				Owner/Desginer/Developer ("Vendor", "we", “company” "us" or "our").
			</p>

			<p class="font-m">
				By downloading, accessing and using the Mobile Application/website and Services, you acknowledge that you
				have read, understood, and agree to be bound by the terms of this Agreement and therefore you should make
				sure that you read them carefully before using the app.
			</p>

			<p class="font-m">
				If you are entering into this Agreement on behalf of a business or other legal entity, you represent that
				you have the authority to bind such entity to this Agreement, in which case the terms "User", "you" or
				"your" shall refer to such entity. If you do not have such authority, or if you do not agree with the terms
				of this Agreement, you must not accept this Agreement and may not access and use the Mobile
				Application/website and Services.
			</p>

			<p class="font-m">
				You acknowledge that this Agreement is a contract between you and the Vendor, even though it is electronic
				and is not physically signed by you, and it governs your use of the Mobile Application/website and
				Services. You're not allowed to copy, or modify the app, any part of the app, or our trademarks in any way.
				You're not allowed to attempt to extract the source code of the app, and you also shouldn't try to
				translate the app into other languages or make derivative versions. The app itself, and all the trademarks,
				copyright, database rights and other intellectual property rights related to it, still belong to Sharanyu.
			</p>

			<p class="font-m">
				Sharanyu is committed to ensuring that the app is as useful and efficient as possible. For that reason, we
				reserve the right to make changes to the app or to charge for its services, at any time and for any reason.
				We will never charge you for the app or its services without making it very clear to you exactly what
				you're paying for.
			</p>

			<h2 class="subheading">Registration and Account</h2>
			<hr />
			<p class="font-m">
				You become a user of Mobile Application/website by completing the registration of an account for Services
				("Account"). This Agreement takes effect as soon as you indicate your acceptance of these terms. You may
				not become a User if you are under the age of 18. Services is not intended for persons under 18 and the
				Company will not knowingly collect personal information from persons under the age of 18.
			</p>

			<p class="font-m">
				If you register/ create an account in the Mobile Application/website, you are responsible for maintaining
				the security of your account and you are fully responsible for all activities that occur under the account
				and any other actions taken in connection with it. We may, but have no obligation to, monitor and review
				new accounts before you may sign up and start using the Services. Providing false contact information of
				any kind may result in the termination of your account. You must immediately notify us of any unauthorized
				uses of your account or any other breaches of security. We will not be liable for any acts or omissions by
				you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend,
				disable, or delete your account (or any part thereof) if we determine that you have violated any provision
				of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we
				delete your account for the foregoing reasons, you may not re-register for our Services. We may block your
				email address and Internet protocol address to prevent further registration.
			</p>

			<p class="font-m">
				Your Account may also include billing information you provide to the Vendor for the purchase of
				Subscriptions Content and Services and any physical merchandise offered for purchase through the Mobile
				Application/website. You may not reveal, share or otherwise allow others to use your password or Account
				except as otherwise specifically authorized by the Company.
			</p>

			<p class="font-m">
				You are responsible for the confidentiality of your login and password and for the security of your device,
				whichever, with which you access the Mobile Application/website. The Vendor is not responsible for the use
				of your password and Account or for all of the communication and activity on Services that results from use
				of your login name and password by you, by any person to whom you may have intentionally or by negligence
				disclosed your login and/ or password in violation of this confidentiality provision. Unless it results
				from the Company's negligence or fault, the Company is not responsible for the use of your Account by a
				person who fraudulently used your login and password without your permission. If you believe that the
				confidentiality of your login and or password may have been compromised, you must notify the Company via
				the support without any delay.
			</p>

			<p class="font-m">
				Your Account, including any information pertaining to it (eg contact information billing information
				Account history and Subscriptions, etc.), is strictly personal You may therefore not sell or charge others
				for the right to use your Account, or otherwise transfer your Account, nor may you sell charge others for
				the right to use, or transfer any Subscriptions other than if and as expressly permitted by this Agreement
				(including any Subscription Terms) or as otherwise specifically permitted by the Vendor.
			</p>

			<h2 class="subheading">Subscriptions; Content and Services</h2>
			<hr />
			<p class="font-m">
				As a User you may obtain access to certain services, software and content available to Users. The Services
				and any other software, content and updates you download or access via Mobile Application/website,
				including but not limited to the Vendor or third-party content, and any virtual items you purchase in
				Mobile Application/website are referred to in this Agreement as "Content and Services”. The rights to
				access and/or use any Contents and Services accessible through Mobile Application/website are referred to
				in this Agreement as "Subscriptions”.
			</p>

			<p class="font-m">
				Each Subscription allows you to access particular Content and Services. Some Subscriptions may impose
				additional terms specific to that Subscription ("Subscription Terms"). The Subscription Terms and the
				Company Privacy Policy are binding on you once you indicate your acceptance of them or of this Agreement.
				Business section and advertisement section are subscription based. Charges are mentioned within the
				segment.
			</p>

			<h2 class="subheading">Payment Processing</h2>
			<hr />
			<p class="font-m">
				Payment processing related to Content and Services and/or physical goods purchased on the Mobile
				Application/website is performed by either the Vendor directly or by the Vendor's affiliates on behalf of
				the Vendor depending on the type of payment method used. This has been designed as such because payment
				terms are directly with vendors as mentioned in vendor agreement.
			</p>

			<h2 className="subheading">BILLING, PAYMENT AND OTHER SUBSCRIPTIONS</h2>
			<hr />

			<p className="font-m">
				You agree to pay all fees or charges to your Account in accordance with the fees, charges and billing terms
				in effect at the time a fee or charge is due and payable. You also agree to pay all applicable taxes. You
				must provide the Vendor with valid payment information in connection with your orders. By providing the
				Vendor with your payment information, you agree that
				<ul>
					<li>
						The Vendor is authorized to immediately invoice your Account for all fees and charges due and payable
						to the Vendor hereunder,
					</li>
					<br />
					<li>
						The Vendor is authorized to share any payment information and instructions required to complete the
						payment transactions with its third-party payment service providers (e.g., credit card transaction
						processing, merchant settlement, and related services)
					</li>
					<br />
					<li>
						No additional notice or consent is required for the foregoing authorizations. You agree to immediately
						notify the Vendor of any change in your payment information The Vendor reserves the right at any time
						to change its prices and billing methods. If payment cannot be charged to your payment card or your
						payment is returned for any reason, the Vendor reserves the right to either suspend or terminate your
						access to the paid for services.
					</li>
				</ul>
			</p>

			<p className="font-m">
				<b>- Payment Authorization</b>
			</p>
			<p className="font-m">
				When you provide payment information to the Vendor or to one of its payment processors, you represent to
				the Vendor that you are the authorized user of the Card, PIN, key or account associated with that payment,
				and you authorize the Vendor to charge your Credit Card or to process your payment with the chosen
				third-party payment processor for any Subscription, Product or other fees incurred by you.The Vendor may
				require you to provide your address or other information in order to meet their obligations under
				applicable tax law.{" "}
			</p>

			<p className="font-m">
				If your use of Services is subject to any type of Use or Goods & Services Tax or any other tax for that
				matter, then the Vendor may also charge you for those taxes, in addition to the Subscription or other fees.{" "}
			</p>

			<p className="font-m">
				You agree that you will not use IP proxying or other methods to disguise the place of your residence,
				whether to circumvent geographical restrictions on content, to purchase at pricing not applicable to your
				geography, or for any other purpose. If you do this, the Vendor may terminate your access to your Account.{" "}
			</p>

			<p className="font-m">
				<b>- Responsibility for Charges Associated With Your Account</b>
			</p>
			<p className="font-m">
				As the Account holder, you are responsible for all charges incurred, including applicable taxes, and all
				purchases made by you or anyone that uses your Account, including your family or friends. If you cancel
				your Account, the Vendor reserves the right to collect fees, surcharges or costs incurred before
				cancellation. Any delinquent or unpaid Accounts must be settled before the Vendor will allow you to
				register again.{" "}
			</p>

			<p className="font-m">
				<b>- Free Subscriptions</b>
			</p>
			<p className="font-m">
				In some cases, the Vendor may offer a free Subscription to certain services, software and content. As with
				all Subscriptions, you are always responsible for any Internet service provider, telephone and other
				connection fees that you may incur when using Services, even when the Vendor offers a free Subscription.{" "}
			</p>

			<p className="font-m">
				<b>- Third Party Sites/Apps</b>
			</p>
			<p className="font-m">
				Mobile Application/website may provide links to other third party sites/ apps. Some of these sites/apps may
				charge separate fees, which are not included in and are in addition to any Subscription or other fees that
				you may pay to the Vendor. Mobile Application/website may also provide access to third-party vendors, who
				provide content, goods and/or services on Services or the Internet. Any separate charges or obligations you
				incur in your dealings with these third parties are your responsibility, the Vendor makes no
				representations or warranties, either express or implied, regarding any third party site/apps. In
				particular, the Vendor makes no representation or warranty that any service or subscription offered via
				third-party vendors will not change or be suspended or terminated. Further to this, the commission payable
				will be as per the clause.{" "}
			</p>

			<p className="font-m">
				<b>- Advertisements</b>
			</p>
			<p className="font-m">
				The Advertisement section is solely for Plant Nurseries, Fertilizer shops, Horticulturists,
				Gardening/Horticultural Youtube Channels, Gardening/ Horticultural blogs and websites, Gardners,
				Landscapers, Plant and gardening apps and Horticulture equipments. Further, the advertisements that are not
				part of the category(as mentioned above) and/or is improper shall be prone to removal.{" "}
			</p>

			<h2 className="subheading">LINKS TO OTHER RESOURCES</h2>
			<hr />

			<p className="font-m">
				Although the Mobile Application/website and Services may link to other resources (such as websites, Mobile
				Application/websites, etc.), we are not, directly or indirectly, implying any approval, association,
				sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated herein. Some
				of the links in the Mobile Application/website may be "affiliate links". This means if you click on the
				link and purchase an item, the Vendor will receive an affiliate commission. We are not responsible for
				examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the
				content of their resources. We do not assume any responsibility or liability for the actions, products,
				services, and content of any other third parties. You should carefully review the legal statements and
				other conditions of use of any resource which you access through a link in the Mobile Application/website
				and Services. Your linking to any other off-site resources is at your own risk.{" "}
			</p>

			<h2 className="subheading">PROHIBITED USES</h2>
			<hr />

			<p className="font-m">
				In addition to other terms as set forth in the Agreement, you are prohibited from using the Mobile
				Application/website and Services or Content:
				<ul>
					<li>For any unlawful purpose;</li>
					<li>To solicit others to perform or participate in any unlawful acts;</li>
					<li>
						To violate any international, national, federal or state regulations, rules, laws, or local ordinances;
					</li>
					<li>
						To infringe upon or violate our intellectual property rights or the intellectual property rights of
						others;
					</li>
					<li>
						To harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on
						gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;
					</li>
					<li>To submit false or misleading information;</li>
					<li>
						To upload or transmit viruses or any other type of malicious code that will or may be used in any way
						that will affect the functionality or operation of the Mobile Application/website and Services, third
						party products and services, or the Internet;
					</li>
					<li>To spam, phish, pharm, pretext, spider, crawl, or scrape;</li>
					<li>For any obscene or immoral purpose; or</li>
					<li>
						To interfere with or circumvent the security features of the Mobile Application/website and Services,
						third party products and services, or the Internet. We reserve the right to terminate your use of the
						Mobile Application/website and Services for violating any of the prohibited uses.
					</li>
				</ul>
			</p>

			<h2 className="subheading">INTELLECTUAL PROPERTY RIGHTS</h2>
			<hr />

			<p className="font-m">
				"Intellectual Property Rights" means all present and future rights conferred by statute, common law or
				equity in or in relation to any copyright and related rights, trademarks, designs, patents, inventions,
				goodwill and the right to sue for passing off, rights to inventions, rights to use, and all other
				intellectual property rights, in each case whether registered or unregistered and including all
				applications and rights to apply for and be granted, rights to claim priority from, such rights and all
				similar or equivalent rights or forms of protection and any other results of intellectual activity which
				subsist or will subsist now or in the future in any part of the world.{" "}
			</p>

			<p className="font-m">
				This Agreement does not transfer to you any intellectual property owned by the Vendor or third parties, and
				all rights, titles, and interests in and to such property will remain (as between the parties) solely with
				the Vendor. All trademarks, service marks, graphics and logos used in connection with the Mobile
				Application/website and Services, are trademarks or registered trademarks of the Vendor or its licensors.
				Other trademarks, service marks, graphics and logos used in connection with the Mobile Application/website
				and Services may be the trademarks of other third parties. Your use of the Mobile Application/website and
				Services grants you no right or license to reproduce or otherwise use any of the Vendor or third party
				trademarks.{" "}
			</p>

			<h2 className="subheading">LIMITATION OF LIABILITY</h2>
			<hr />

			<p className="font-m">
				To the fullest extent permitted by applicable law, in no event will the Vendor, its affiliates, directors,
				officers, employees, agents, suppliers or licensors be liable to any person for any indirect, incidental,
				special, punitive, cover or consequential damages (including, without limitation, damages for lost profits,
				revenue, sales, goodwill, use of content, impact on business, business interruption, loss of anticipated
				savings, loss of business opportunity) however caused, under any theory of liability, including, without
				limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if the liable
				party has been advised as to the possibility of such damages or could have foreseen such damages. To the
				maximum extent permitted by applicable law, the aggregate liability of the Vendor and its affiliates,
				officers, employees, agents, suppliers and licensors relating to the services will be limited to an amount
				greater of one dollar or any amounts actually paid in cash by you to the Vendor for the prior one month
				period prior to the first event or occurrence giving rise to such liability. The limitations and exclusions
				also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose.{" "}
			</p>

			<h2 className="subheading">INDEMNIFICATION</h2>
			<hr />

			<p className="font-m">
				You agree to indemnify and hold the Vendor and its affiliates, directors, officers, employees, agents,
				suppliers and licensors harmless from and against any liabilities, losses, damages or costs, including
				reasonable attorneys' fees, incurred in connection with or arising from any third party allegations,
				claims, actions, disputes, or demands asserted against any of them as a result of or relating to your
				Content, your use of the Mobile Application/website and Services or any wilful misconduct on your part.{" "}
			</p>

			<h2 className="subheading">SEVERABILITY</h2>
			<hr />

			<p className="font-m">
				All rights and restrictions contained in this Agreement may be exercised and shall be applicable and
				binding only to the extent that they do not violate any applicable laws and are intended to be limited to
				the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable. If any
				provision or portion of any provision of this Agreement shall be held to be illegal, invalid or
				unenforceable by a court of competent jurisdiction, it is the intention of the parties that the remaining
				provisions or portions thereof shall constitute their agreement with respect to the subject matter hereof,
				and all such remaining provisions or portions thereof shall remain in full force and effect.{" "}
			</p>

			<h2 className="subheading">DISPUTE RESOLUTION</h2>
			<hr />

			<p className="font-m">
				The formation, interpretation, and performance of this Agreement and any disputes arising out of it shall
				be governed by the substantive and procedural laws applicable to the State of Chhattisgarh, India without
				regard to its rules on conflicts or choice of law and, to the extent applicable, the laws of India. The
				exclusive jurisdiction and venue for actions related to the subject matter hereof shall be the courts
				located in Raipur, Chhattisgarh, and you hereby submit to the personal jurisdiction of such courts. You
				hereby waive any right to a jury trial in any proceeding arising out of or related to this Agreement.{" "}
			</p>

			<h2 class="subheading">Changes and Amendments</h2>
			<hr />
			<p class="font-m">
				We reserve the right to modify this Agreement or its terms relating to the Mobile Application/website and
				Services at any time, effective upon posting of an updated version of this Agreement in the Mobile
				Application/website. When we do, we will revise the updated date at the bottom of this page. Continued use
				of the Mobile Application/website and Services after any such changes shall constitute your consent to such
				changes.
			</p>

			<h2 class="subheading">Rights of Third Parties</h2>
			<hr />
			<p class="font-m">
				You agree that this Agreement is not intended to confer and does not confer any rights or remedies upon any
				person other than the parties to this Agreement.
			</p>

			<h2 class="subheading">Complying with Law</h2>
			<hr />
			<p class="font-m">
				The Company's obligations are subject to existing laws and legal process and the Company may comply with
				law enforcement or regulatory requests or requirements notwithstanding any contrary term of this agreement.
			</p>

			<h2 class="subheading">Acceptance of These Terms</h2>
			<hr />
			<p class="font-m">
				You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing
				and using the Mobile Application/website and Services you agree to be bound by this Agreement. If you do
				not agree to abide by the terms of this Agreement, you are not authorized to access or use the Mobile
				Application/website and Services.
			</p>

			<h2 class="subheading">Contacting Us</h2>
			<hr />
			<p class="font-m">
				If you would like to contact us to understand more about this Agreement or wish to contact us concerning
				any matter relating to it, you may send an email to info@sharanyu.com.
			</p>

			<br />
			<p class="font-m text-center">This agreement was last updated on April 4th 2024.</p>
		</Layout>
	)
}

export default ApplicationTerms
