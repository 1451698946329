import React, { useEffect } from "react"
import Navbar from "../../components/Navbar"
import Footer from "../../components/Footer"
import { useSearchParams } from "react-router-dom"

const Layout = ({ children, ...props }) => {
	const [searchParams] = useSearchParams()
	const isMobileView = Boolean(searchParams.get("mobile_view"))
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0
		})
	}, [])

	return (
		<div className="page-wrapper static-page" {...props}>
			{!isMobileView && <Navbar />}
			<div className="container content">{children}</div>
			{!isMobileView && <Footer />}
		</div>
	)
}

export default Layout
