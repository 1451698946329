import React from "react"
import Layout from "./Layout"

const Profile = () => {
	return (
		<Layout>
			<h1 className="heading">About The Founder</h1>
			<hr />
			<p className="quote flex">
				<q>The only way to do great work is to love what you do.</q>
				{" - "}
				<b>Steve Jobs</b>
			</p>
			<p className="font-m">
				Have you ever played parent to a potted plant? <b>Rahul Rao</b> has always.
				<br />
				He noticed his green thumb when he was preparing for the Civil Services examination, which he cultivated
				further over the years. He left the preparation midway and gave his full-time plowing his thoughts and
				shaping the ideas.
			</p>
			<p className="font-m">
				In the process, Rahul not just developed a deep understanding of plants but also noticed people's struggle
				to grow one in their backyard. While traveling and meeting people, he also observed that many nature
				lovers, especially the ones living in interior areas had to travel several kilometers to visit a nursery to
				buy a particular plant of their choice. Thus, Rahul came up with <b>Sharanyu</b>.
			</p>
			<div className="highlighted">
				<q>
					The concept behind Sharanyu is to make plants available for people and be a platform to provide reliable
					and practical information to grow plants. A platform which will fulfill all needs related to green babies
				</q>
				<span> - Says Rahul Rao</span>
			</div>
			<p className="font-m">
				In 2018, the journey began at home, Rahul started growing plants at home and sharing information on
				Instagram. The followers grew and so the enthusiasm. In a short period, Sharanyu earned a reputation of
				trust and a go-to place for anything related to plants. With Sharanyu, efforts have been taken to convert
				the complicated Horticulture field into its simplest form. Sales, service and guidence on the website are
				designed to help people so that they can reach the right categories for their needs.
			</p>
			<p className="font-m">
				We hope, when things will become easy and reachable people would like to indulge more in plants and healthy
				living.
			</p>
			<div className="highlighted flex col start gap-l">
				<b>
					<span className="font-m">REQUEST TO ALL THE NATURE ENTHUSIAST</span>
				</b>
				<q>
					My continuous effort is to answer the maximum number of queries raised in our channels and on social
					media, unfortunately, we only have 24 hours a day. I highly appreciate people for showing their interest
					in plants and ultimately planting the world green. Keep supporting!
				</q>
				<p className="font-m">
					<b>Rahul Rao</b>
					<br />
					<b>Founder, Director</b>
					<br />
					<b>Sharanyu</b>
				</p>
			</div>
		</Layout>
	)
}

export default Profile
