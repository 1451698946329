import React, { useState } from "react"
import { ReactComponent as MapMarker } from "../assets/map.svg"
import { ReactComponent as SearchIcon } from "../assets/search.svg"
import { ReactComponent as DropDown } from "../assets/dropdown.svg"
import { ReactComponent as ArrowRight } from "../assets/right.svg"
import Select from "./Select"
import BlurWatcher from "./BlurWatcher"
import Spinner, { spinnerTypes } from "./Spinner"
import axios from "axios"
import { useNavigate } from "react-router-dom"

let fetchTimer
const MasterSearch = ({ selectedCity, onCityChange, cities }) => {
	const [searchState, setSearchState] = useState({ text: "" })
	const [results, setResults] = useState()
	const navigate = useNavigate()

	const fetchResults = async () => {
		try {
			const response = await axios.post(`/product/search`, {
				filter: searchState?.text,
				selected_city: selectedCity
			})
			if (response.data) setResults(response.data)
		} catch (error) {
			console.log(error)
		}
		setSearchState(prev => ({ ...prev, loading: false }))
	}
	const onChange = e => {
		const triggerFetch = e.target.value.length > 3
		setSearchState(prev => ({ ...prev, text: e.target.value, visible: triggerFetch }))
		clearTimeout(fetchTimer)
		if (triggerFetch) {
			fetchTimer = setTimeout(fetchResults, 1000)
			if (!searchState?.loading) setSearchState(prev => ({ ...prev, loading: true }))
		} else if (searchState?.loading) setSearchState(prev => ({ ...prev, loading: false }))
	}

	return (
		<div id="master-search" className="relative">
			<Select
				IdIcon={MapMarker}
				DropDownIcon={DropDown}
				wrapperId={"location-select"}
				options={cities}
				onSelect={onCityChange}
				defaultSelection={selectedCity}
			/>
			<div className="divider" />
			<div>
				<SearchIcon />
				<input type="text" value={searchState.text} onChange={onChange} placeholder="Search products..." />
			</div>
			{searchState.visible && (
				<BlurWatcher
					id="search-results"
					onClickOutside={() => setSearchState(prev => ({ ...prev, visible: false }))}
				>
					<div className="relative">
						<table>
							<tbody>
								{results?.length > 0 ? (
									results?.map(business => (
										<>
											{business?.products?.map(i => (
												<tr key={business._id + i} onClick={() => navigate(`/business/${business?.id}`)}>
													<td>
														<div className="flex col start no-gap">
															<span className="font-md">
																<b>{i}</b>
															</span>
															<small className="font-xs">{business?.title}</small>
														</div>
													</td>
													<td>
														<ArrowRight />
													</td>
												</tr>
											))}
										</>
									))
								) : (
									<tr>
										<td colSpan={3} className="text-center font-s light-font">
											<em>No products found</em>
										</td>
									</tr>
								)}
							</tbody>
						</table>
						{searchState?.loading && <Spinner type={spinnerTypes.pulse} absolute={true} />}
					</div>
				</BlurWatcher>
			)}
		</div>
	)
}

export default MasterSearch
