import React from "react"
import { BiSolidPin } from "react-icons/bi"
import Layout from "./Layout"

const PlantsNutritionalRequirement = () => {
	const nutrientParts = {
		Marcronutrients:
			"Marcronutrients are required in larger quantities in plants. Those are Nitrogen, phosphorous, potassium, calcium, magnesium, sulfur, carbon, oxygen and Hydrogen.",
		Micronutrients:
			"Micronutrients are required in lesser quantity. Those are iron, Boron, chlorine, Manganese, zinc, copper, molybdenum, nickel."
	}
	return (
		<Layout>
			<h1 className="heading">Plants Nutritional Requirement</h1>
			<hr />
			<p className="font-m">
				Every plant has having Nutritional requirement. Some gather nutrition from their growing environment. Some
				required to be feed from outside source as a fertilizer. So before fulfilling plants nutritional
				requirement it is vital to know about nutrients, which plants assimilates through soil. Nutrients are
				classified into two parts:
			</p>

			<div className="flex col start gap-m font-m">
				{Object.keys(nutrientParts).map(i => (
					<div className="flex row start gap-m">
						<BiSolidPin className="theme-font font-l" style={{ rotate: "-30deg", minWidth: "22px" }} />
						<span className="font-md">{i}</span>
					</div>
				))}
			</div>

			{Object.keys(nutrientParts)?.map(i => (
				<>
					<h2 className="subheading">{i}</h2>
					<hr />
					<p className="font-m">{nutrientParts[i]}</p>
				</>
			))}
		</Layout>
	)
}

export default PlantsNutritionalRequirement
