import React from "react"
import Spinner, { spinnerTypes } from "./Spinner"

const Prompt = ({ Actions, heading, message, info, loading }) => {
	return (
		<div className="overlay">
			<div className="prompt relative">
				{heading && <h2 className="subheading">{heading}</h2>}
				{message && <p className="font-md">{message}</p>}
				{info && (
					<div className="obj-info">
						<span className="font-xs">{info}</span>
					</div>
				)}
				<div className="flex modal-actions">
					<Actions />
				</div>
				{loading && <Spinner type={spinnerTypes.spinner} {...{ absolute: true }} />}
			</div>
		</div>
	)
}

export default Prompt
