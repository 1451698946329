import React, { useState } from "react"
import { ReactComponent as Cross } from "../assets/cross.svg"
import NumberInput from "./MobileInput"
import OTPInput from "./OTPInput"
import { useNavigate } from "react-router-dom"
import Spinner, { spinnerTypes } from "./Spinner"
import { generateOTP } from "../utils/methods"
import ErrorLine from "./ErrorLine"
import { ReactComponent as CheckBox } from "../assets/checkbox.svg"
import { ReactComponent as CheckBoxEmpty } from "../assets/checkboxempty.svg"

const Signup = ({ context, submitCreds }) => {
	const { setMasterState } = context
	const navigate = useNavigate()
	const [errorMessage, setErrorMessage] = useState()
	const [credencials, setCredencials] = useState({})
	const [OTPState, setOTPState] = useState()
	const [loading, setLoading] = useState()
	const endpoint = "/auth/signup"

	const onChange = e => setCredencials(prev => ({ ...prev, [e.target.name]: e.target.value }))
	const generateOTPWrapper = async () => {
		setLoading(true)
		try {
			if (errorMessage) setErrorMessage()
			await generateOTP(credencials.phone_number)
			setOTPState({ visible: true, generated: Date.now() })
		} catch (error) {
			setErrorMessage("Failed to generate OTP, please try again.")
			console.log(error)
		}
		setLoading(false)
	}
	const onSubmit = async e => {
		try {
			e.preventDefault()
			setLoading(true)
			if (errorMessage) setErrorMessage()
			if (!OTPState?.generated) return generateOTPWrapper()
			const payload = credencials
			delete payload.email
			delete payload.password

			if (OTPState?.visible) payload.OTP = OTPState.value?.join("")
			await submitCreds({ endpoint, credencials })
			navigate("/partner-with-us")
		} catch (error) {
			setErrorMessage(error.response.data.error)
			setLoading(false)
		}
	}

	return (
		<div className="authPage login">
			<div className="flex between">
				<h2 className="login">Sign Up</h2>
				<button className="close-btn flex" onClick={() => setMasterState({})}>
					<Cross />
				</button>
			</div>
			<form onSubmit={onSubmit}>
				<div className="input-fields">
					{OTPState?.visible ? (
						<OTPInput
							value={OTPState?.value}
							onChange={value => setOTPState(prev => ({ ...prev, value }))}
							changeNumber={() => setOTPState(null)}
							phoneNumber={credencials?.phone_number}
						/>
					) : (
						<>
							<input
								type="text"
								className="theme-input"
								placeholder="Full Name"
								name="name"
								defaultValue={""}
								value={credencials.name}
								onChange={onChange}
								required={true}
								disabled={loading}
							/>
							<NumberInput
								verify={false}
								name="phone_number"
								defaultValue={""}
								value={credencials.phone_number}
								onChange={onChange}
								required={true}
								disabled={loading}
							/>
						</>
					)}
					<ErrorLine message={errorMessage} />
					<div>
						<label style={{ display: "flex", alignItems: "center", gap: "10px", position: "relative" }}>
							<input
								type="checkbox"
								style={{ position: "absolute", top: "25%", left: 5, zIndex: -1, pointerEvents: "none" }}
								value={Boolean(credencials?.termsAccepted)}
								onChange={e => setCredencials(prev => ({ ...prev, termsAccepted: e.target.checked }))}
								required
							/>
							{credencials?.termsAccepted ? <CheckBox /> : <CheckBoxEmpty />}
							<div className="font-md">
								<span>I agree with the </span>
								<a
									style={{ display: "inline" }}
									target="_blank"
									href={"/application-terms"}
									className="underline theme-font"
									rel="noreferrer"
								>
									application terms
								</a>
							</div>
						</label>
					</div>
				</div>
				<button type="submit" className="theme-btn relative primary font-md" disabled={loading}>
					{loading && <Spinner type={spinnerTypes.progressBar} {...{ transparent: 1, absolute: 1 }} />}
					Sign Up {OTPState?.visible ? "" : "With OTP"}
				</button>
			</form>

			<div className="redirect">
				<span className="font-xs">Alreay Have An Account?</span>
				<button className="font-xs theme-font" onClick={() => setMasterState({ login: true })} disabled={loading}>
					Log In
				</button>
			</div>
		</div>
	)
}

export default Signup
