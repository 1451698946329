import React, { useState } from "react"
import { OTPModal } from "./OTPInput"

const NumberInput = ({ placeholder, verify = true, verifyOTP, onVerified, ...props }) => {
	const [OTPModalState, setOTPModalState] = useState()
	return (
		<>
			<div className="mnum-field flex">
				<div className="input">
					<span className="font-md">+91</span>
					<input
						type="text"
						placeholder={placeholder || "Enter your phone number"}
						onKeyDownCapture={e => /^[A-Za-z]$/.test(e.key) && e.preventDefault()}
						pattern="[0-9]{10}"
						minLength={10}
						maxLength={10}
						{...props}
					/>
				</div>
				{verify && (
					<button
						type="button"
						onClick={() => verifyOTP?.() || setOTPModalState(true)}
						className="theme-btn primary"
						disabled={props?.disabled}
					>
						Verify
					</button>
				)}
			</div>
			{OTPModalState && (
				<OTPModal phoneNumber={props?.value} close={() => setOTPModalState()} onVerified={onVerified} />
			)}
		</>
	)
}

export default NumberInput
