import React from "react"
import { LuSearch } from "react-icons/lu"

const SearchBar = props => {
	return (
		<div className="search-bar theme-input flex font-s">
			<LuSearch className="font-l" />
			<input type="text" className="font-s" {...props} />
		</div>
	)
}

export default SearchBar
